export const FLOW_ACTIONS = {
  FCL_LOGIN: "FCL_LOGIN",
  FCL_LOGOUT: "FCL_LOGOUT",
};

export const fclLogin = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({
      type: FLOW_ACTIONS.FCL_LOGIN,
      data,
    });
  };
};

export const fclLogout = () => {
  return async (dispatch: Function) => {
    dispatch({
      type: FLOW_ACTIONS.FCL_LOGOUT,
    });
  };
};
