export const isDefined = (obj: any) => {
  return typeof obj !== "undefined";
};

export const isDefinedNonNull = (obj: any) => {
  return typeof obj !== "undefined" && obj !== null;
};

export const isEmpty = (obj: string | null) => {
  return typeof obj === "undefined" || obj == null || obj.length === 0;
};

export const isBlank = (obj: string | null) => {
  return typeof obj === "undefined" || obj == null || obj.trim().length === 0;
};
