import { Link } from "react-router-dom";
import { OwnedContentBrand } from "src/service/owned_content_brand_api";

export default function BrandHeroImage({
  brand,
}: {
  brand: OwnedContentBrand;
}) {
  const src = brand.hero_image_url
    ? brand.hero_image_url
    : "/img/Roadstead_logo_y.png";
  const alt = brand.hero_image_url ? brand.name : "Roadstead logo";

  return (
    <Link to={`/brand/${brand.account}`}>
      <div className="flex justify-center bg-gray-100">
        <img className="max-h-52 object-contain" src={src} alt={alt} />
      </div>
    </Link>
  );
}
