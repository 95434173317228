import { merge } from "lodash";
import { config } from "src/config/config";
import { LOGIN_ACTIONS } from "src/actions/ui/login_actions";

const defaultState = {
  show: false,
  nextPath: "",
};

export const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.SHOW_LOGIN_MODAL: {
      if (config.APP_DEBUG) {
        console.log("LOGIN_ACTIONS.SHOW_LOGIN_MODAL: ", action.data);
      }

      let newState = {
        show: action.data.show,
        nextPath: action.data.nextPath,
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
