import { forwardRef } from "react";

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  isError?: boolean;

  // size は input 要素がすでに持っているので blockSize という名前にしている。
  // サイズの選択肢は tailwindcss の font size の種類に合わせている。
  blockSize?: "base" | "lg";
};

// input 要素にスタイルを適用したコンポーネント。
// input 要素以外の要素は原則追加せず、別コンポーネントを作成してください。
// react-hook-form が DOM にアクセスすることがあるため、forwardRef を使用している。
export default forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  { isError = false, blockSize = "base", className, ...props },
  ref
) {
  const inputClass = (() => {
    const baseClass =
      "border rounded focus:ring-2 focus:outline-none focus:border-transparent";
    const colorClass = isError
      ? "border-red-500 focus:ring-red-400"
      : "border-gray-300 focus:ring-indigo-500";
    const sizeClass = (() => {
      switch (blockSize) {
        case "base":
          return "text-base px-4 py-2";
        case "lg":
          return "text-lg px-6 py-4";
      }
    })();

    return [baseClass, colorClass, sizeClass, className].join(" ");
  })();

  return <input ref={ref} className={inputClass} {...props} />;
});
