import { useTranslation } from "react-i18next";

export default function NotInitialized() {
  const { t } = useTranslation("components/error/not_initialized/index");
  return (
    <div className="flex justify-center text-xs text-red-500">
      <div>
        <p>{t("notInitialized")}</p>
        <p>{t("reload")}</p>
      </div>
    </div>
  );
}
