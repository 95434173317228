import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";
import { getDRMTokenAPI, getFairPlayCertAPI } from "src/service/drm_api";

export const DRM_ACTIONS = {
  GET_DRM_TOKEN: "GET_DRM_TOKEN",
  INIT_GET_DRM_TOKEN: "INIT_GET_DRM_TOKEN",
  START_GET_DRM_TOKEN: "START_GET_DRM_TOKEN",
  START_DRM_PROBE: "START_DRM_PROBE",
  SET_DRM_SUPPORT: "SET_DRM_SUPPORT",
  GET_FAIRPLAY_CERT: "GET_FAIRPLAY_CERT",
  INIT_GET_FAIRPLAY_CERT: "INIT_GET_FAIRPLAY_CERT",
  START_GET_FAIRPLAY_CERT: "START_GET_FAIRPLAY_CERT",
};

export const getDRMToken = (data: any) => ({
  type: DRM_ACTIONS.GET_DRM_TOKEN,
  data,
});

export const startDRMProbe = () => ({
  type: DRM_ACTIONS.START_DRM_PROBE,
});

export const setDRMSupport = (data: any) => ({
  type: DRM_ACTIONS.SET_DRM_SUPPORT,
  data,
});

export const initGetDRMToken = () => ({
  type: DRM_ACTIONS.INIT_GET_DRM_TOKEN,
});

export const startGetDRMToken = () => ({
  type: DRM_ACTIONS.START_GET_DRM_TOKEN,
});

export const getFairPlayCert = (data: any) => ({
  type: DRM_ACTIONS.GET_FAIRPLAY_CERT,
  data,
});

export const initGetFairPlayCert = () => ({
  type: DRM_ACTIONS.INIT_GET_FAIRPLAY_CERT,
});

export const startGetFairPlayCert = () => ({
  type: DRM_ACTIONS.START_GET_FAIRPLAY_CERT,
});

export const getDRMTokenDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    drmSystem: params.drmSystem,
    assetKid: params.assetKid,
    persistent: params.persistent,
  };

  dispatch(startGetDRMToken());

  return getDRMTokenAPI(options)
    .done((data) => {
      if (config.APP_DEBUG) {
        console.log("Received DRM token: ", data);
      }

      let drmData = {
        drmSystem: params.drmSystem,
        mediaType: params.mediaType,
        response: data,
        ajaxStat: AjaxStatus.DONE,
      };
      dispatch(getDRMToken(drmData));
    })
    .fail((err) => {
      if (err.status >= 500) {
        if (config.APP_DEBUG) {
          console.log("InternalServerError in accessing to API");
        }
        dispatch(
          getDRMToken({
            response: err.status,
            ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
          })
        );
      } else if (err.status === 403) {
        if (config.APP_DEBUG) {
          console.log("Forbidden in accessing to API");
        }
        dispatch(
          getDRMToken({
            response: err.status,
            ajaxStat: AjaxStatus.FORBIDDEN,
          })
        );
      } else {
        if (config.APP_DEBUG) {
          console.error("Error in getting DRM token");
        }
        dispatch(
          getDRMToken({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      }
    });
};

export const getFairPlayCertDispatch = () => (dispatch: Function) => {
  dispatch(startGetFairPlayCert());

  return getFairPlayCertAPI()
    .done((data) => {
      if (config.APP_DEBUG) {
        console.log("Received FairPlay cert: ", data);
      }

      dispatch(
        getFairPlayCert({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting FairPlay cert");
      }
      dispatch(
        getFairPlayCert({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};
