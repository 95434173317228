import { useMemo } from "react";
import { featureFlags } from "src/config/feature_flags";

type featureFlagMember = keyof typeof featureFlags;

export default function useFeatureFlag(flagName: featureFlagMember) {
  const flag = useMemo(() => {
    return featureFlags[flagName];
  }, [flagName]);

  return flag.isEnabled;
}
