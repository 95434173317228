import { isEmpty } from "./validate_util";

export function mapToJson(map: any) {
  return JSON.stringify([...map]);
}

export function jsonToMap(jsonStr: string | null) {
  if (!isEmpty(jsonStr)) {
    return new Map(JSON.parse(jsonStr!));
  } else {
    return new Map();
  }
}
