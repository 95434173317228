import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";
import {
  depositCashAPI,
  depositUsdcAPI,
  getCashBalanceAPI,
  getUserTransactionsAPI,
} from "src/service/fund_api";

export const FUND_ACTIONS = {
  DEPOSIT_USDC: "DEPOSIT_USDC",
  INIT_DEPOSIT_USDC: "INIT_DEPOSIT_USDC",
  START_DEPOSIT_USDC: "START_DEPOSIT_USDC",
  DEPOSIT_CASH: "DEPOSIT_CASH",
  INIT_DEPOSIT_CASH: "INIT_DEPOSIT_CASH",
  START_DEPOSIT_CASH: "START_DEPOSIT_CASH",
  GET_CASH_BALANCE: "GET_CASH_BALANCE",
  INIT_GET_CASH_BALANCE: "INIT_GET_CASH_BALANCE",
  START_GET_CASH_BALANCE: "START_GET_CASH_BALANCE",
  GET_USER_TRANSACTIONS: "GET_USER_TRANSACTIONS",
  INIT_GET_USER_TRANSACTIONS: "INIT_GET_USER_TRANSACTIONS",
  START_GET_USER_TRANSACTIONS: "START_GET_USER_TRANSACTIONS",
};

export const depositUsdc = (data: any) => ({
  type: FUND_ACTIONS.DEPOSIT_USDC,
  data,
});

export const initDepositUsdc = () => ({
  type: FUND_ACTIONS.INIT_DEPOSIT_USDC,
});

export const startDepositUsdc = () => ({
  type: FUND_ACTIONS.START_DEPOSIT_USDC,
});

export const depositCash = (data: any) => ({
  type: FUND_ACTIONS.DEPOSIT_CASH,
  data,
});

export const initDepositCash = () => ({
  type: FUND_ACTIONS.INIT_DEPOSIT_CASH,
});

export const startDepositCash = () => ({
  type: FUND_ACTIONS.START_DEPOSIT_CASH,
});

export const getCashBalance = (data: any) => ({
  type: FUND_ACTIONS.GET_CASH_BALANCE,
  data,
});

export const initGetCashBalance = () => ({
  type: FUND_ACTIONS.INIT_GET_CASH_BALANCE,
});

export const startGetCashBalance = () => ({
  type: FUND_ACTIONS.START_GET_CASH_BALANCE,
});

export const getUserTransactions = (data: any) => ({
  type: FUND_ACTIONS.GET_USER_TRANSACTIONS,
  data,
});

export const initGetUserTransactions = () => ({
  type: FUND_ACTIONS.INIT_GET_USER_TRANSACTIONS,
});

export const startGetUserTransactions = () => ({
  type: FUND_ACTIONS.START_GET_USER_TRANSACTIONS,
});

export const depositUsdcDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    amount: params.amount,
  };

  dispatch(startDepositUsdc());

  return depositUsdcAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Deposit USDC: ", data);
      }
      dispatch(depositUsdc(data));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in fund recharging");
      }
      dispatch(
        depositUsdc({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const depositCashDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    amount: params.amount,
  };

  dispatch(startDepositCash());

  return depositCashAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Deposit cash: ", data);
      }
      dispatch(depositCash(data));
      if (params.onDeposited) {
        params.onDeposited(data.balance);
      }
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in fund recharging");
      }
      dispatch(
        depositCash({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
      if (params.onDeposited) {
        params.onDeposited(0);
      }
    });
};

export const getCashBalanceDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
  };

  dispatch(startGetCashBalance());

  return getCashBalanceAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Got cash balance: ", data);
      }
      dispatch(
        getCashBalance({
          response: data,
          ajaxStat: AjaxStatus.FAILED,
        })
      );
      if (params.onBalance) {
        params.onBalance(data.balance);
      }
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting cash balance");
      }
      dispatch(
        getCashBalance({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
      if (params.onBalance) {
        params.onBalance(0);
      }
    });
};

export const getUserTransactionsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    };

    dispatch(startGetUserTransactions());

    return getUserTransactionsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received user transactions: ", data);
        }
        let transData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getUserTransactions(transData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting user transactions");
        }
        dispatch(
          getUserTransactions({
            response: [],
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };
