import { merge } from "lodash";
import { config } from "src/config/config";
import { ASSET_FILER_ACTIONS } from "src/actions/ui/asset_filter";

const defaultState = {
  show: true,
};

export const assetFilterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ASSET_FILER_ACTIONS.SHOW_FILTER: {
      if (config.APP_DEBUG) {
        console.log("ASSET_FILER_ACTIONS.SHOW_FILTER: ", action.data);
      }

      let newState = {
        show: action.data,
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
