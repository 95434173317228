import {
  GetRequest,
  PutRequestForm,
  DeleteRequest,
  PostRequestJson,
  PutRequestJson,
} from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const createBrandAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.create_brand}`;
  let data: Record<string, any> = {
    name: options.name,
    account: options.account,
    hero_image: options.hero_image,
    description: options.description,
    kids: options.kids,
  };

  return PostRequestJson(url, data, headers);
};

export const getBrandsByUserAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.brands}`;

  return GetRequest(url, null, headers);
};

export const getBrandDetailsAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.brand(options.account)}`;

  return GetRequest(url, null, headers);
};

export const updateBrandAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.update_brand}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data: Record<string, any> = {
    id: options.brandID,
    name: options.name,
    account: options.account,
    hero_image: options.hero_image,
    description: options.description,
    kids: options.kids,
  };
  return PutRequestJson(url, data, headers);
};

export const getCreatedAssetsByUserAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.brand_created_assets}?brand_id=${options.brandId}`;

  return GetRequest(url, null, headers);
};
