import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage"; // これで redux store が localStorage に永続化される。

import RootReducer from "../reducers/root_reducer.ts";
import RootMiddleware from "../middleware/root_middleware.js";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const configureStore = (preloadedState = {}) => {
  let store = createStore(persistedReducer, preloadedState, RootMiddleware);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
