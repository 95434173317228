import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";
import {
  getUserNotificationsAPI,
  readNotificationAPI,
  getNotificationSummaryAPI,
} from "src/service/notification_api";

export const NOTIFICATION_ACTIONS = {
  GET_USER_NOTIFICATIONS: "GET_USER_NOTIFICATIONS",
  INIT_GET_USER_NOTIFICATIONS: "INIT_GET_USER_NOTIFICATIONS",
  START_GET_USER_NOTIFICATIONS: "START_GET_USER_NOTIFICATIONS",
  GET_NOTIFICATION_SUMMARY: "GET_NOTIFICATION_SUMMARY",
  INIT_GET_NOTIFICATION_SUMMARY: "INIT_GET_NOTIFICATION_SUMMARY",
  START_GET_NOTIFICATION_SUMMARY: "START_GET_NOTIFICATION_SUMMARY",
};

export const getUserNotifications = (data: any) => ({
  type: NOTIFICATION_ACTIONS.GET_USER_NOTIFICATIONS,
  data,
});
export const initGetUserNotifications = () => ({
  type: NOTIFICATION_ACTIONS.INIT_GET_USER_NOTIFICATIONS,
});
export const startGetUserNotifications = () => ({
  type: NOTIFICATION_ACTIONS.START_GET_USER_NOTIFICATIONS,
});

export const getUserNotificationsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    };

    dispatch(startGetUserNotifications());

    return getUserNotificationsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received user notifications: ", data);
        }
        let transData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getUserNotifications(transData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting user Notifications");
        }
        dispatch(
          getUserNotifications({
            response: [],
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getNotificationSummary = (data: any) => ({
  type: NOTIFICATION_ACTIONS.GET_NOTIFICATION_SUMMARY,
  data,
});

export const initGetNotificationSummary = () => ({
  type: NOTIFICATION_ACTIONS.INIT_GET_NOTIFICATION_SUMMARY,
});

export const startGetNotificationSummary = () => ({
  type: NOTIFICATION_ACTIONS.START_GET_NOTIFICATION_SUMMARY,
});

export const getNotificationSummaryDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
    };

    dispatch(startGetNotificationSummary());

    return getNotificationSummaryAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received notification summary: ", data);
        }
        let summaryData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getNotificationSummary(summaryData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting notification summary");
        }
        dispatch(
          getNotificationSummary({
            response: [],
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const readNotificationDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      notificationId: params.notificationId,
    };

    return readNotificationAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Read notification done");
        }
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Fail to read notification");
        }
      });
  };
