import { config } from "src/config/config";
import { APICallStatus } from "src/service/api_types";
import { merge } from "lodash";
import { PAYMENT_ACTIONS } from "src/actions/ui/payment_actions";

const defaultState = {
  paymentToken: {
    status: { status: APICallStatus.INIT },
    token: null,
  },
};

export const paymentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAYMENT_ACTIONS.GET_PAYMENT_TOKEN: {
      if (config.APP_DEBUG) {
        console.log("PAYMENT_ACTIONS.GET_PAYMENT_TOKEN: ", action.data);
      }
      return {
        ...state,
        paymentToken: {
          status: { status: APICallStatus.INIT },
          token: action.data.token,
        },
      };
    }
    case PAYMENT_ACTIONS.INIT_GET_PAYMENT_TOKEN: {
      if (config.APP_DEBUG) {
        console.log("PAYMENT_ACTIONS.INIT_GET_PAYMENT_TOKEN: ", action.data);
      }

      let newState = {
        paymentToken: {
          status: { status: APICallStatus.INIT },
          token: null,
        },
      };
      return merge({}, state, newState);
    }
    case PAYMENT_ACTIONS.START_GET_PAYMENT_TOKEN: {
      if (config.APP_DEBUG) {
        console.log(" PAYMENT_ACTIONS.START_GET_PAYMENT_TOKEN: ", action.data);
      }

      let newState = {
        paymentToken: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }

    default:
      return state;
  }
};
