export const ACCOUNT_HOME_ACTIONS = {
  SWITCH_ASSETS_CATEGOROY: "SWITCH_ASSETS_CATEGOROY",
};

export const switchAssetsCategory = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      activeCategory: params.activeCategory,
      tabSwitched: params.tabSwitched,
    };
    dispatch({
      type: ACCOUNT_HOME_ACTIONS.SWITCH_ASSETS_CATEGOROY,
      data,
    });
  };
};
