import {
  AjaxStatus,
  APICallStatus,
  TRMOperationStatus,
  UserRole,
} from "./api_types";
import { config } from "src/config/config";

export const checkApiStatus = (
  ajaxStat: AjaxStatus,
  apiStat: string | null
) => {
  let stat: TRMOperationStatus = {
    status: APICallStatus.INIT,
  };

  if (ajaxStat === AjaxStatus.FAILED) {
    stat.status = APICallStatus.FAILED;
  } else if (ajaxStat === AjaxStatus.FORBIDDEN) {
    stat.status = APICallStatus.FAILED;
    stat.errorCode = "403";
  } else if (ajaxStat === AjaxStatus.UNPROCESSABLE_ENTITY) {
    stat.status = APICallStatus.FAILED;
    stat.errorCode = "422";
  } else if (ajaxStat === AjaxStatus.INTERNAL_SERVER_ERROR) {
    stat.status = APICallStatus.FAILED;
    stat.errorCode = "500";
  } else {
    if (apiStat) {
      if (apiStat === "ok") {
        stat.status = APICallStatus.SUCCEEDED;
      } else {
        stat.status = APICallStatus.FAILED;
      }
    } else {
      stat.status = APICallStatus.SUCCEEDED;
    }
  }

  return stat;
};

export const isORHUser = (userRole: string | null) => {
  let role = config.DEFAULT_USER_ROLE;
  if (
    userRole &&
    (userRole === UserRole.ORH || userRole === UserRole.STANDARD)
  ) {
    role = userRole;
  }

  return role === UserRole.ORH;
};

export const isAdminUser = (userRole: string | null) => {
  return userRole === UserRole.ADMIN;
};
