import $, { data } from "jquery";
import { config } from "src/config/config";
import { isDefinedNonNull } from "./validate_util";

const setupXhr = (options: any) => {
  var xhr = new window.XMLHttpRequest();

  xhr.upload.addEventListener(
    "progress",
    function (evt) {
      if (evt.lengthComputable) {
        var percent = (evt.loaded / evt.total) * 100;
        if (isDefinedNonNull(options) && options.uploadProgress) {
          options.uploadProgress(percent);
        }
      }
    },
    false
  );
  xhr.addEventListener(
    "progress",
    function (evt) {
      if (evt.lengthComputable) {
        var percent = (evt.loaded / evt.total) * 100;
        if (isDefinedNonNull(options) && options.downloadProgress) {
          options.downloadProgress(percent);
        }
      }
    },
    false
  );

  return xhr;
};

export const GetRequest = (
  url: string,
  data?: any,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("GET: " + url);
  }

  return $.ajax({
    url,
    method: "GET",
    data: data !== "undefined" ? data : {},
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_GET_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const GetBinaryRequest = (
  url: string,
  data?: any,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("GET: " + url);
  }

  return $.ajax({
    url,
    method: "GET",
    dataType: "binary",
    processData: false,
    cache: false,
    data: data !== "undefined" ? data : {},
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_GET_TIMEOUT,
    xhrFields: {
      responseType: "arraybuffer",
    },
    xhr: function () {
      return setupXhr(progress);
    },
    beforeSend: function (jqXHR, settings) {
      if (settings.dataType === "binary") {
        if (settings.xhr) {
          settings.xhr().responseType = "arraybuffer";
        }
      }
    },
  });
};

export const PatchRequestJson = (
  url: string,
  data: object,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PATCH: " + url);
  }

  return $.ajax({
    url,
    method: "PATCH",
    data: JSON.stringify(data),
    contentType: "application/json;charset=UTF-8",
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_PATCH_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PatchRequestForm = (
  url: string,
  data: FormData,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PATCH: " + url);
  }

  return $.ajax({
    url,
    method: "PATCH",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_PATCH_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PutRequestJson = (
  url: string,
  data: object,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PUT: " + url);
  }

  return $.ajax({
    url,
    method: "PUT",
    data: JSON.stringify(data),
    contentType: "application/json;charset=UTF-8",
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_PUT_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PutRequestForm = (
  url: string,
  data: FormData,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PUT: " + url);
  }

  return $.ajax({
    url,
    method: "PUT",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_PUT_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PutRequestFile = (
  url: string,
  data: File,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PUT: " + url);
  }

  return $.ajax({
    url,
    method: "PUT",
    data: data,
    cache: false,
    contentType: "multipart/form-data",
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_TIMEOUT_INFINITE,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PutRequestChunkFile = (
  url: string,
  data: File,
  headers?: any,
  progress?: any,
  timeout?: number
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PUT: " + url);
  }

  return $.ajax({
    url,
    method: "PUT",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: timeout ? timeout : config.AJAX_TIMEOUT_INFINITE,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PutRequestChunkBuf = (
  url: string,
  data: Uint8Array,
  headers?: any,
  progress?: any,
  timeout?: number
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("PUT: " + url);
  }

  return $.ajax({
    url,
    method: "PUT",
    cache: false,
    data: data,
    contentType: false,
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: timeout ? timeout : config.AJAX_TIMEOUT_INFINITE,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PostRequestJson = (
  url: string,
  data: object,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("POST: " + url);
  }

  return $.ajax({
    url,
    method: "POST",
    data: JSON.stringify(data),
    // contentType: "application/json;charset=UTF-8",
    contentType: "application/json",
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_POST_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PostRequestForm = (
  url: string,
  data: FormData,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("POST: " + url);
  }

  return $.ajax({
    url,
    method: "POST",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_POST_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const PostRequestText = (
  url: string,
  data: string,
  headers?: any,
  progress?: any
): JQuery.Promise<any> => {
  if (config.APP_DEBUG) {
    console.log("POST: " + url);
  }

  return $.ajax({
    method: "POST",
    url,
    data: data,
    contentType: "text/plain",
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_POST_TIMEOUT,
    xhr: function () {
      return setupXhr(progress);
    },
  });
};

export const DeleteRequest = (url: string, headers?: any) => {
  if (config.APP_DEBUG) {
    console.log("DELETE: " + url);
  }

  return $.ajax({
    url,
    method: "DELETE",
    headers: headers !== "undefined" ? headers : {},
    timeout: config.AJAX_DELETE_TIMEOUT,
  });
};
