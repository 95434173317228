import { OwnedContentBrandGroup } from "src/service/owned_content_brand_api";
import AssetGrid from "src/components/asset_grid";
import { useCallback } from "react";
import { setCurrentAsset } from "src/actions/ui/asset_details";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AssetCategory } from "src/service/api_types";
import { Content } from "src/service/content_api";

export default function Group({ group }: { group: OwnedContentBrandGroup }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onContentClicked = useCallback(
    (content: Content) => {
      dispatch(
        setCurrentAsset({
          public: false,
          category: AssetCategory.COLLECTED,
          asset: content,
        })
      );
      navigate("/asset");
    },
    [dispatch, navigate]
  );

  return (
    <div className="space-y-2">
      <div className="text-lg font-bold">{group.name}</div>

      <AssetGrid
        assets={group.contents}
        onAssetClicked={(content: Content) => onContentClicked(content)}
        container="collected"
      />
    </div>
  );
}
