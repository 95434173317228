import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/reducers/root_reducer";
import ReactPlayer from "react-player/lazy";
import { parseMediaTypeFromUrl, parsePreviewUrl } from "src/utils/asset_util";
import { AssetBusinessCond, AssetMediaType } from "src/service/api_types";
import { useTranslation } from "react-i18next";
import {
  initKidAssetsCollectedByUser,
  initKidAssetsRentedByUser,
} from "src/actions/user_asset_actions";
import { getDateStringFromUnix, isExpiredFromUnix } from "src/utils/date_util";
import useFeatureFlag from "src/hooks/use_feature_flag";

type AssetGridProps = {
  columns_large?: number;
  columns_small?: number;
  assets: any[];
  onAssetClicked: Function;
  sortingData?: any[];
  defaultSort?: number;
  onSortChanged?: (value: number) => void;
  container?:
    | "collected"
    | "brandSale"
    | "brandRental"
    | "created"
    | "newrelease"
    | "rented";
};

const defaultProps: Partial<AssetGridProps> = {
  defaultSort: 1,
};

export default function AssetGrid(props: AssetGridProps) {
  props = { ...defaultProps, ...props };
  const showFilter = useSelector((state: RootState) => state.assetFilter.show);
  const { assets, sortingData, defaultSort, onSortChanged, container } = props;
  let [playState, setPlayState] = useState({});
  const { t } = useTranslation("components/asset_grid/index");
  const dispatch = useDispatch();

  const onAssetClicked = (asset: any) => {
    if (container === "collected" && !isOwned(asset)) {
      return;
    }
    if (isPreparingCollected(asset)) {
      return;
    }
    if (isPreparingRented(asset)) {
      return;
    }
    if (
      container === "brandRental" &&
      asset.rental_listing_copies_count === null
    ) {
      return;
    }

    switch (container) {
      case "collected": {
        dispatch(initKidAssetsCollectedByUser());
        break;
      }
      case "rented": {
        dispatch(initKidAssetsRentedByUser());
        break;
      }
      default: {
        break;
      }
    }
    props.onAssetClicked(asset);
  };

  const fclCurrentUser = useSelector(
    (state: RootState) => state.session.fclCurrentUser
  );

  useEffect(() => {
    let play = {};
    if (assets) {
      assets.forEach((asset) => {
        play[asset.asset_token_id] = false;
      });
      setPlayState(play);
    }
  }, [assets]);

  const isOwned = (asset: any): boolean => {
    return asset.owned_copies_count > 0;
  };

  const isPreviousOwned = (asset: any): boolean => {
    return asset.previous_owned_copies_count > 0 && !isOwned(asset);
  };

  const isPreparingCollected = (asset: any): boolean => {
    return (
      container === "collected" &&
      asset.owned_copies_count === 0 &&
      asset.purchase_processing_copies_count > 0
    );
  };

  const isPreparingRented = (asset: any): boolean => {
    return (
      container === "rented" &&
      asset.renting_processing_copies_count !== 0 &&
      (!asset.my_max_expiry ||
        (asset.my_max_expiry && isExpiredFromUnix(asset.my_max_expiry)))
    );
  };

  const isCreator = (asset: any): boolean => {
    return fclCurrentUser.addr === asset.creator_account_id;
  };

  const isSecondarySaleEnabled = (asset: any): boolean => {
    return asset.asset_kid_setting?.secondary_sale_enabled === true;
  };

  // 命名上 isTradeEnabled を使用できなかったため、このファイルでのみ isTradeFeatureFlagEnabled を使用している
  const isTradeFeatureFlagEnabled = useFeatureFlag("trade");

  const isTradeEnabled = (asset: any): boolean => {
    return (
      isTradeFeatureFlagEnabled &&
      asset.asset_kid_setting?.trade_enabled === true
    );
  };

  // TODO: trade の機能フラグ削除後、条件を「isCreator(asset) || isTradeEnabled(asset)」に変更する
  const isShowTradeListingCopiesCount = (asset: any): boolean => {
    return (
      isTradeFeatureFlagEnabled &&
      (isCreator(asset) || asset.asset_kid_setting?.trade_enabled === true)
    );
  };

  // マイコンテンツ「collected」「rented」画面
  // 自身が著作権者の場合:      所有数（出品数 / レンタル出品数 / トレード出品数）/ 作成数
  // 自身が著作権者でない場合:   所有数（出品数 / トレード出品数）/ 作成数
  //    ただし、以下の場合はそれぞれの要素を非表示にする
  //        二次販売を許可しないコンテンツ：　出品数、トレード出品数を隠す
  //        トレード出品を許可しないコンテンツ：　トレード出品数を隠す

  const getListingCountInfo = (asset: any): string => {
    let count_info = "";
    if (
      isCreator(asset) ||
      isSecondarySaleEnabled(asset) ||
      isTradeEnabled(asset)
    ) {
      count_info += "（";
      if (isCreator(asset) || isSecondarySaleEnabled(asset)) {
        count_info += t("saleListedCount", {
          count: asset.my_sale_listing_copies_count,
        });
        if (isShowTradeListingCopiesCount(asset)) {
          count_info += " / ";
        }
      }
      if (isShowTradeListingCopiesCount(asset)) {
        count_info += t("tradeListedCount", {
          count: asset.my_trade_listing_copies_count,
        });
      }
      if (isCreator(asset)) {
        count_info +=
          " / " +
          t("rentalListedCount", {
            count: asset.my_rental_listing_copies_count,
          });
      }
      count_info += "）";
    }
    return count_info;
  };

  return (
    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <ul
        role="list"
        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        {assets.map((asset) => {
          return (
            <li
              key={asset.unique_id}
              className="relative"
              onClick={(e) => onAssetClicked(asset)}
            >
              {/* 現在の Roadstead で画像の本編はサポートしていないので、asset.media_type === AssetMediaType.IMAGE が true になることはない。 */}
              {/* そのため、この場合のメンテはしない。 */}
              {asset.media_type === AssetMediaType.IMAGE ? (
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  {asset.poster_url ? (
                    <>
                      <img
                        src={asset.poster_url}
                        alt=""
                        className={`pointer-events-none object-contain
                          ${
                            isPreparingCollected(asset) ||
                            isPreparingRented(asset)
                              ? "disabled:opacity-30"
                              : "group-hover:opacity-75"
                          }
                        `}
                      />
                      {container === "brandRental" &&
                      asset.rental_listing_copies_count === null ? (
                        <></>
                      ) : (
                        <button
                          type="button"
                          className="focus:outline-none absolute inset-0 z-40"
                        >
                          {isPreparingCollected(asset) ||
                          isPreparingRented(asset) ? (
                            <span>{t("preparing")}</span>
                          ) : (
                            <></>
                          )}
                        </button>
                      )}
                    </>
                  ) : (
                    <img
                      src="/img/default-poster.png"
                      alt=""
                      className="pointer-events-none object-contain group-hover:opacity-75"
                    />
                  )}
                </div>
              ) : container === "collected" &&
                !isOwned(asset) &&
                !isPreparingCollected(asset) &&
                !isPreviousOwned(asset) ? (
                <div className="aspect-h-7 aspect-w-10">
                  <div className="flex cursor-default select-none justify-center rounded-lg bg-black">
                    <div className="h-full w-6/12">
                      <div className="flex h-full w-full items-center justify-center rounded bg-gray-800 p-1">
                        <div className="flex h-full w-full items-center justify-center rounded border border-gray-500">
                          <img
                            src="/img/Roadstead_gray_01.svg"
                            alt=""
                            className="w-4/12"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-black focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    {parseMediaTypeFromUrl(parsePreviewUrl(asset)) ===
                    AssetMediaType.IMAGE ? (
                      <img
                        src={parsePreviewUrl(asset)}
                        alt=""
                        className="pointer-events-none object-contain group-hover:opacity-75"
                      />
                    ) : playState[asset.unique_id] ? (
                      <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={parsePreviewUrl(asset)}
                        playing={true}
                      />
                    ) : asset.poster_url ? (
                      <>
                        <img
                          src={asset.poster_url}
                          alt=""
                          className={`pointer-events-none object-contain
                            ${
                              isPreparingCollected(asset) ||
                              isPreparingRented(asset)
                                ? "opacity-30"
                                : container === "brandRental" &&
                                  asset.rental_listing_copies_count === null
                                ? ""
                                : "group-hover:opacity-75"
                            }
                          `}
                        />
                        {isPreparingCollected(asset) ||
                        isPreparingRented(asset) ? (
                          <div className="flex cursor-default select-none items-center justify-center text-white">
                            <span>{t("preparing")}</span>
                          </div>
                        ) : (
                          <>
                            {container === "brandRental" &&
                            asset.rental_listing_copies_count === null ? (
                              <></>
                            ) : (
                              <button
                                type="button"
                                className="focus:outline-none"
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <img
                        src="/img/default-poster.png"
                        alt=""
                        className="pointer-events-none object-contain group-hover:opacity-75"
                      />
                    )}
                    {/* {parseMediaTypeFromUrl(parsePreviewUrl(asset)) !==
                    AssetMediaType.IMAGE ? (
                      <button
                        className="play-controls"
                        onClick={(e) => onTogglePlay(e, asset)}
                      >
                        {playState[asset.unique_id] ? (
                          <PauseCircleFilled className="h16 w-16" />
                        ) : (
                          <PlayCircleFilled className="h-16 w-16" />
                        )}
                      </button>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </>
              )}

              {/* アセット名 */}
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                {asset.title}
              </p>

              {/*
                出品状況および出品価格
                TODO: レンタルおよびオークション実装時、表示価格をasset.min_sale_priceから変更
               */}
              <div className="pointer-events-none block text-sm font-medium text-gray-500">
                {/* 出品価格 */}
                {asset.sale_listing_copies_count > 0 &&
                asset.min_sale_price !== null ? (
                  <div
                    className={`list-item ${
                      showFilter ? "show-filter" : "hide-filter"
                    }`}
                  >
                    {asset.min_sale_price === asset.max_sale_price ? (
                      <p className="label">
                        {t("saleListedPrice")}{" "}
                        <span className="value text-gray-900">
                          {t("price", { price: asset.min_sale_price })}
                        </span>
                      </p>
                    ) : (
                      <p className="label">
                        {t("saleListedPrice")}{" "}
                        <span className="value text-gray-900">
                          {t("price", { price: asset.min_sale_price }) +
                            "–" +
                            t("price", { price: asset.max_sale_price })}
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {/* レンタル価格 */}
                {asset.rental_listing_copies_count > 0 &&
                asset.min_rental_price &&
                asset.min_rental_price !== null &&
                asset.rentable_copies_count > 0 ? (
                  <div
                    className={`list-item ${
                      showFilter ? "show-filter" : "hide-filter"
                    }`}
                  >
                    {asset.min_rental_price === asset.max_rental_price ? (
                      <p className="label">
                        {t("rentalPrice")}{" "}
                        <span className="value text-gray-900">
                          {t("price", { price: asset.min_rental_price })}
                        </span>
                      </p>
                    ) : (
                      <p className="label">
                        {t("rentalPrice")}{" "}
                        <span className="value text-gray-900">
                          {t("price", { price: asset.min_rental_price }) +
                            "–" +
                            t("price", { price: asset.max_rental_price })}
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {/* オークション価格 */}
                {asset.type_of_listing === AssetBusinessCond.AUCTION &&
                asset.min_sale_price &&
                asset.min_sale_price !== null ? (
                  <div
                    className={`list-item ${
                      showFilter ? "show-filter" : "hide-filter"
                    }`}
                  >
                    <p className="label">Auction Price</p>
                    <span className="value">¥{asset.min_sale_price}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {/* ブランド名 */}
              {container &&
              !["brandSale", "brandRental", "collected"].includes(container) ? (
                <div className="pointer-events-none block text-sm font-medium text-gray-500">
                  <div className="list-item">
                    <p className="label">
                      <span>{t("brandName")} </span>
                      <span className="value text-gray-900">
                        {asset.brand?.name ? asset.brand.name : t("noBrands")}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* 著作権者、所有者欄 */}
              {asset.is_sold_out && container === "brandSale" ? (
                <div className="pointer-events-none block text-sm font-medium text-gray-500">
                  <div className="list-item">
                    <p className="text-gray-900">{t("noContentsAvailable")}</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="pointer-events-none block text-sm font-medium text-gray-900">
                    {container === "rented" ? (
                      <>
                        {/* 視聴期限 */}
                        {asset.my_max_expiry
                          ? t("expiry", {
                              date: getDateStringFromUnix(
                                asset.my_max_expiry,
                                "YYYY/MM/DD HH:mm z"
                              ),
                            })
                          : ""}
                      </>
                    ) : (
                      <>
                        {container === "brandSale" ||
                        container === "brandRental" ? (
                          // ブランド詳細画面
                          <>
                            {/* 販売出品の場合： 購入可能数 */}
                            {asset.sale_listing_copies_count > 0 &&
                            asset.min_sale_price &&
                            asset.min_sale_price !== null ? (
                              // TODO: 購入可能数は残りが少なくなった時だけ表示する。
                              // t("buyableCount", {
                              //   count: asset.sale_listing_copies_count,
                              // })
                              <></>
                            ) : (
                              <></>
                            )}
                            {/* レンタルの場合：　レンタル可能数 */}
                            {asset.rental_listing_copies_count > 0 &&
                            asset.min_rental_price &&
                            asset.min_rental_price !== null ? (
                              asset.rentable_copies_count > 0 ? (
                                asset.asset_kid_setting
                                  ?.secondary_rental_enabled ? (
                                  t("rentableCount", {
                                    count: asset.rentable_copies_count,
                                  })
                                ) : (
                                  <></>
                                )
                              ) : (
                                t("allRentedOut")
                              )
                            ) : container === "brandRental" &&
                              asset.rental_listing_copies_count === null ? (
                              <>{t("saleEnded")}</>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {t("ownedCount", {
                              count: asset.owned_copies_count,
                            })}
                            {getListingCountInfo(asset)}
                            {` / ${t("createdCount", {
                              count: asset.total_copies_count,
                            })}`}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
