export const PAYMENT_ACTIONS = {
  GET_PAYMENT_TOKEN: "GET_PAYMENT_TOKEN",
  INIT_GET_PAYMENT_TOKEN: "INIT_GET_PAYMENT_TOKEN",
  START_GET_PAYMENT_TOKEN: "START_GET_PAYMENT_TOKEN",
};

export const getPaymentToken = (data: any) => ({
  type: PAYMENT_ACTIONS.GET_PAYMENT_TOKEN,
  data,
});

export const initGetPaymentToken = () => ({
  type: PAYMENT_ACTIONS.INIT_GET_PAYMENT_TOKEN,
});

export const startGetPaymentToken = () => ({
  type: PAYMENT_ACTIONS.START_GET_PAYMENT_TOKEN,
});

export const getPaymentTokenDispatch =
  (params: any) => (dispatch: Function) => {
    dispatch(startGetPaymentToken());
    dispatch(
      getPaymentToken({
        token: params.token,
      })
    );
  };
