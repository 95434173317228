import { config } from "src/config/config";
import { ACCOUNT_HOME_ACTIONS } from "src/actions/ui/account_home";

const defaultState = {
  activeCategory: null,
  tabSwitched: false,
};

export const accountHomeReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ACCOUNT_HOME_ACTIONS.SWITCH_ASSETS_CATEGOROY: {
      if (config.APP_DEBUG) {
        console.log(
          "ACCOUNT_HOME_ACTIONS.SWITCH_ASSETS_CATEGOROY: ",
          action.data
        );
      }

      return {
        ...state,
        activeCategory: action.data.activeCategory,
        tabSwitched: action.data.tabSwitched,
      };
    }
    default:
      return state;
  }
};
