import { config } from "src/config/config";
import { getUserInfoAPI } from "src/service/account_api";
import { AjaxStatus } from "src/service/api_types";
import {
  getAssetBidsAPI,
  getAssetBidSummaryAPI,
  getAssetTransactionsAPI,
} from "src/service/public_asset_api";
import { initGetDRMToken } from "../drm_actions";

export const ASSET_DETAILS_ACTIONS = {
  SET_CURRENT_ASSET: "SET_CURRENT_ASSET",
  INIT_GET_CREATOR_INFO: "INIT_GET_CREATOR_INFO",
  START_GET_CREATOR_INFO: "START_GET_CREATOR_INFO",
  GET_CREATOR_INFO: "GET_CREATOR_INFO",
  INIT_GET_OWNER_INFO: "INIT_GET_OWNER_INFO",
  START_GET_OWNER_INFO: "START_GET_OWNER_INFO",
  GET_OWNER_INFO: "GET_OWNER_INFO",
  GET_ASSET_TRANSACTION: "GET_ASSET_TRANSACTION",
  INIT_GET_ASSET_TRANSACTION: "INIT_GET_ASSET_TRANSACTION",
  START_GET_ASSET_TRANSACTION: "START_GET_ASSET_TRANSACTION",
  GET_ASSET_BIDS: "GET_ASSET_BIDS",
  INIT_GET_ASSET_BIDS: "INIT_GET_ASSET_BIDS",
  START_GET_ASSET_BIDS: "START_GET_ASSET_BIDS",
  GET_ASSET_BID_SUMMARY: "GET_ASSET_BID_SUMMARY",
  INIT_GET_ASSET_BID_SUMMARY: "INIT_GET_ASSET_BID_SUMMARY",
  START_GET_ASSET_BID_SUMMARY: "START_GET_ASSET_BID_SUMMARY",
};

export const initGetCreatorInfo = () => ({
  type: ASSET_DETAILS_ACTIONS.INIT_GET_CREATOR_INFO,
});

export const startGetCreatorInfo = () => ({
  type: ASSET_DETAILS_ACTIONS.START_GET_CREATOR_INFO,
});

export const getCreatorInfo = (data: any) => ({
  type: ASSET_DETAILS_ACTIONS.GET_CREATOR_INFO,
  data,
});

export const initGetOwnerInfo = () => ({
  type: ASSET_DETAILS_ACTIONS.INIT_GET_OWNER_INFO,
});

export const startGetOwnerInfo = () => ({
  type: ASSET_DETAILS_ACTIONS.START_GET_OWNER_INFO,
});

export const getOwnerInfo = (data: any) => ({
  type: ASSET_DETAILS_ACTIONS.GET_OWNER_INFO,
  data,
});

export const getAssetTransactions = (data: any) => ({
  type: ASSET_DETAILS_ACTIONS.GET_ASSET_TRANSACTION,
  data,
});

export const initGetAssetTransactions = () => ({
  type: ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_TRANSACTION,
});

export const startGetAssetTransactions = () => ({
  type: ASSET_DETAILS_ACTIONS.START_GET_ASSET_TRANSACTION,
});

export const setCurrentAsset = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      public: params.public,
      category: params.category,
      asset: params.asset,
    };
    dispatch(initGetDRMToken());
    dispatch(initGetCreatorInfo());
    dispatch(initGetOwnerInfo());
    dispatch(initGetAssetBidSummary());
    dispatch(initGetAssetBids());
    dispatch({
      type: ASSET_DETAILS_ACTIONS.SET_CURRENT_ASSET,
      data,
    });
  };
};

export const getCreatorInfoDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    userID: params.creatorUserID,
  };

  dispatch(startGetCreatorInfo());

  return getUserInfoAPI(options)
    .done((data) => {
      if (config.APP_DEBUG) {
        console.log("Received asset creator info: ", data);
      }
      let userInfo = {
        userID: params.creatorUserID,
        response: data,
      };
      dispatch(getCreatorInfo(userInfo));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting asset creator info");
      }
    });
};

export const getOwnerInfoDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    userID: params.ownerUserID,
  };

  dispatch(startGetOwnerInfo());

  return getUserInfoAPI(options)
    .done((data) => {
      if (config.APP_DEBUG) {
        console.log("Received assset owner info: ", data);
      }
      let userInfo = {
        userID: params.ownerUserID,
        response: data,
      };
      dispatch(getOwnerInfo(userInfo));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting assset owner info");
      }
    });
};

export const getAssetTransactionsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetTokenID: params.assetTokenID,
    };

    dispatch(startGetAssetTransactions());

    return getAssetTransactionsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received asset transactions: ", data);
        }
        let transData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetTransactions(transData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting asset transactions");
        }
        dispatch(
          getAssetTransactions({
            response: [],
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getAssetBids = (data: any) => ({
  type: ASSET_DETAILS_ACTIONS.GET_ASSET_BIDS,
  data,
});

export const initGetAssetBids = () => ({
  type: ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BIDS,
});

export const startGetAssetBids = () => ({
  type: ASSET_DETAILS_ACTIONS.START_GET_ASSET_BIDS,
});

export const getAssetBidsDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    assetTokenID: params.assetTokenID,
  };

  dispatch(startGetAssetBids());

  return getAssetBidsAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Received asset bids: ", data);
      }
      let bidsData = {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        response: data,
        ajaxStat: AjaxStatus.DONE,
      };
      dispatch(getAssetBids(bidsData));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting asset bids");
      }
      dispatch(
        getAssetBids({
          response: [],
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const getAssetBidSummary = (data: any) => ({
  type: ASSET_DETAILS_ACTIONS.GET_ASSET_BID_SUMMARY,
  data,
});

export const initGetAssetBidSummary = () => ({
  type: ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BID_SUMMARY,
});

export const startGetAssetBidSummary = () => ({
  type: ASSET_DETAILS_ACTIONS.START_GET_ASSET_BID_SUMMARY,
});

export const getAssetBidSummaryDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      assetTokenID: params.assetTokenID,
    };

    dispatch(startGetAssetBidSummary());

    return getAssetBidSummaryAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received asset bid summary: ", data);
        }
        let bidSummary = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetBidSummary(bidSummary));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting asset bid summary");
        }
        dispatch(
          getAssetBidSummary({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };
