import { config } from "src/config/config";
import { BRAND_DETAILS_ACTIONS } from "src/actions/ui/brand_details_actions";
import { APICallStatus } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import { merge } from "lodash";

const defaultState = {
  currentBrand: null,
  brandDetails: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const brandDetailsReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case BRAND_DETAILS_ACTIONS.SET_CURRENT_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_DETAILS_ACTIONS.SET_CURRENT_BRAND: ", action.data);
      }

      return {
        ...state,
        currentBrand: action.data.brand,
      };
    }
    // get brands
    case BRAND_DETAILS_ACTIONS.GET_BRAND_DETAILS: {
      if (config.APP_DEBUG) {
        console.log("BRAND_DETAILS_ACTIONS.GET_BRAND_DETAIL: ", action.data);
      }

      let brand = action.data.response;
      return {
        ...state,
        brandDetails: {
          data: brand,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case BRAND_DETAILS_ACTIONS.INIT_BRAND_DETAILS: {
      if (config.APP_DEBUG) {
        console.log("BRAND_DETAILS_ACTIONS.INIT_BRAND_DETAIL: ", action.data);
      }

      let newState = {
        brandDetails: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_DETAILS_ACTIONS.START_BRAND_DETAILS: {
      if (config.APP_DEBUG) {
        console.log("BRAND_DETAILS_ACTIONS.START_BRAND_DETAIL: ", action.data);
      }

      let newState = {
        brandDetails: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
