export const ASSET_FILER_ACTIONS = {
  SHOW_FILTER: "SHOW_FILTER",
};

export const showAssetFilter = (show: boolean) => {
  return async (dispatch: Function) => {
    let data = show;
    dispatch({
      type: ASSET_FILER_ACTIONS.SHOW_FILTER,
      data,
    });
  };
};
