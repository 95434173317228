import { merge } from "lodash";
import { config } from "src/config/config";
import { CREATOR_HOME_ACTIONS } from "src/actions/ui/creator_home";

const defaultState = {
  creatorInfo: null,
  activeTab: null,
  tabSwitched: false,
};

export const creatorHomeReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CREATOR_HOME_ACTIONS.SET_CREATOR_INFO: {
      if (config.APP_DEBUG) {
        console.log("CREATOR_HOME_ACTIONS.SET_CREATOR_INFO: ", action.data);
      }

      return {
        ...state,
        creatorInfo: action.data.creatorInfo,
      };
    }
    case CREATOR_HOME_ACTIONS.SWITCH_CREATOR_HOME_TAB: {
      if (config.APP_DEBUG) {
        console.log(
          "CREATOR_HOME_ACTIONS.SWITCH_CREATOR_HOME_TAB: ",
          action.data
        );
      }

      return {
        ...state,
        activeTab: action.data.activeTab,
        tabSwitched: action.data.tabSwitched,
      };
    }
    default:
      return state;
  }
};
