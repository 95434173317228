import { config } from "src/config/config";
import { genMockTeams, genMockPlayers } from "src/service/mock/mock_teams";

export const SPORTS_ACTIONS = {
  GET_ALL_TEAMS: "GET_ALL_TEAMS",
  GET_ALL_PLAYERS: "GET_ALL_PLAYERS",
  GET_PLAYERS_PER_TEAM: "GET_PLAYERS_PER_TEAM",
};

export const getAllTeams = (data: any) => ({
  type: SPORTS_ACTIONS.GET_ALL_TEAMS,
  data,
});

export const getAllPlayers = (data: any) => ({
  type: SPORTS_ACTIONS.GET_ALL_PLAYERS,
  data,
});

export const getPlayersPerTeam = (data: any) => ({
  type: SPORTS_ACTIONS.GET_PLAYERS_PER_TEAM,
  data,
});

export const getAllTeamsDispatch = (params: any) => (dispatch: Function) => {
  let data = genMockTeams(Number(config.SPORTS_CONFIG.TOTAL_TEAMS));
  return dispatch(
    getAllTeams({
      response: data,
    })
  );
};

export const getAllPlayersDispatch = (params: any) => (dispatch: Function) => {
  let data = genMockPlayers(Number(config.SPORTS_CONFIG.TOTAL_PLAYERS));
  return dispatch(
    getAllPlayers({
      response: data,
    })
  );
};

export const getPlayersPerTeamDispatch =
  (params: any) => (dispatch: Function) => {
    let data = genMockPlayers(Number(config.SPORTS_CONFIG.PLAYERS_PER_TEAMS));
    return dispatch(
      getPlayersPerTeam({
        teamID: params.teamID,
        response: data,
      })
    );
  };
