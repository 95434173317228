import { config } from "src/config/config";
import {
  PostRequestJson,
  PutRequestFile,
  GetRequest,
  DeleteRequest,
} from "src/utils/rest_api_util";

export const getPreSignedUrlAPI = (options: any) => {
  const url = `${config.MEDIA_UPLOAD_API.HOST}${config.MEDIA_UPLOAD_API.APIS.asset_upload}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    file_name: options.asset_file_name,
    poster_file_name: options.poster_file_name,
    trailer_file_name: options.trailer_file_name,
    orh_account_id: options.userId,
    title: options.title,
    description: options.description,
    type: options.assetType,
    asset_upload_type: options.assetUploadType,
    total_copies: options.totalCopies,
    asset_md5hash: options.assetMd5,
    poster_md5hash: options.posterMd5,
    trailer_md5hash: options.trailerMd5,
    largeAssetUpload: options.largeAssetUpload,
    metadata: options.metadata,
    startSerialNumber: options.startSerialNumber,
    media_metadata: options.mediaMetadata,
  };
  if (options.assetSubtitles) {
    data["asset_subtitles"] = options.assetSubtitles;
  }
  if (options.trailerSubtitles) {
    data["trailer_subtitles"] = options.trailerSubtitles;
  }
  return PostRequestJson(url, data, headers, null);
};

export const uploadMediaAPI = (options: any) => {
  const url = options.pre_signed_url;
  const headers = {
    "x-amz-meta-uuid": options.uuid,
    "x-amz-meta-orh_account_id": options.orh_account_id,
    "x-amz-meta-asset_upload_type": options.asset_upload_type,
    "x-amz-meta-md5hash": options.md5,
    "x-amz-meta-marketplace_id": config.MARKETPLACE_ID,
    "Content-MD5": options.content_md5,
  };
  const progress = {
    uploadProgress: options.uploadProgress,
  };
  return PutRequestFile(url, options.file, headers, progress);
};

export const getAssetsStatusAPI = (options: any) => {
  const url = `${config.MEDIA_UPLOAD_API.HOST}${config.MEDIA_UPLOAD_API.APIS.assets_status}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    orh_account_id: options.userId,
    include_mint: options.includeMint,
  };
  return GetRequest(url, data, headers, null);
};

export const deleteUploadAPI = (options: any) => {
  const urlString = `${config.MEDIA_UPLOAD_API.HOST}${config.MEDIA_UPLOAD_API.APIS.asset_delete}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let url = new URL(urlString);
  url.searchParams.set("transaction_id", options.transaction_id);

  return DeleteRequest(url.href, headers);
};
