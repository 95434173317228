import GroupCollection from "src/components/collected_contents/brands/detail/group_collection";
import CollectedContentCollectionLink from "./collected_content_collection_link";
import { useSelector } from "react-redux";
import { RootState } from "src/reducers/root_reducer";
import { useCallback, useEffect, useState } from "react";
import {
  OwnedContentBrand,
  getOwnedContentBrandAPI,
} from "src/service/owned_content_brand_api";
import { config } from "src/config/config";
import { Link, useParams } from "react-router-dom";
import BrandHeroImage from "./brand_hero_image";
import { AssetCategory } from "src/service/api_types";
import { useTranslation } from "react-i18next";
import { isExpiredFromUnix } from "src/utils/date_util";
import Contents from "./contents";

export default function CollectedContentsBrandsDetailPage() {
  const { key } = useParams();

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const [brand, setBrand] = useState<OwnedContentBrand | undefined>(undefined);

  const { t } = useTranslation(
    "components/collected_contents/brands/detail/page"
  );

  const fetchOwnedContentBrand = useCallback(
    async (key) => {
      const options = {
        accessToken: accessToken,
        key: key,
      };
      await getOwnedContentBrandAPI(options)
        .done((data, status, xhr) => {
          setBrand(data);
        })
        .fail((err) => {
          if (config.APP_DEBUG) {
            console.error("Error in getOwnedContentBrandsAPI");
          }
        });
    },
    [accessToken]
  );

  const ownedContents = useCallback(() => {
    if (brand && brand.contents) {
      return brand.contents.filter(
        (content) =>
          content.owned_copies_count > 0 ||
          content.purchase_processing_copies_count > 0
      );
    } else {
      return [];
    }
  }, [brand]);

  const previousOwnedContents = useCallback(() => {
    if (brand && brand.contents) {
      return brand.contents.filter(
        (content) =>
          content.previous_owned_copies_count &&
          content.previous_owned_copies_count > 0 &&
          content.owned_copies_count === 0
      );
    } else {
      return [];
    }
  }, [brand]);

  const rentedContentsWithinPeriod = useCallback(() => {
    if (brand && brand.contents) {
      return brand.contents.filter(
        (content) =>
          (content.my_rented_copies_count &&
            content.my_rented_copies_count > 0 &&
            content.my_max_expiry &&
            !isExpiredFromUnix(content.my_max_expiry)) ||
          (content.renting_processing_copies_count &&
            content.renting_processing_copies_count > 0)
      );
    } else {
      return [];
    }
  }, [brand]);

  const rentedContentsExpired = useCallback(() => {
    if (brand && brand.contents) {
      return brand.contents.filter(
        (content) =>
          content.my_rented_copies_count &&
          content.my_rented_copies_count > 0 &&
          content.my_max_expiry &&
          isExpiredFromUnix(content.my_max_expiry)
      );
    } else {
      return [];
    }
  }, [brand]);

  useEffect(() => {
    if (key) {
      const intervalID = setInterval(() => {
        fetchOwnedContentBrand(key);
      }, config.MARKETPLACE_UI.REFRESH_INTERVAL.COLLECTED);

      fetchOwnedContentBrand(key);

      return () => clearInterval(intervalID);
    }
  }, [fetchOwnedContentBrand, key]);

  // TODO: ローディング画面の追加
  if (!brand) return <></>;

  return (
    <div className="mx-auto max-w-4xl space-y-12 p-6">
      <div>
        <div className="inline-block">
          <CollectedContentCollectionLink />
        </div>
      </div>

      <div className="space-y-10">
        <Link to={`/brand/${brand.account}`}>
          <div className="text-xl font-bold">{brand.name}</div>
        </Link>

        <BrandHeroImage brand={brand} />

        <div className="text-xs">{t("waitUntilContentIsDisplayed")}</div>

        <Contents
          contents={rentedContentsWithinPeriod()}
          assetCategory={AssetCategory.RENTED}
          title={t("rentedContentsWithinPeriod")}
          isPublic={false}
          container={"rented"}
        />

        <Contents
          contents={ownedContents()}
          assetCategory={AssetCategory.COLLECTED}
          title={t("collectedContents")}
          isPublic={false}
          container={"collected"}
        />

        <GroupCollection brand={brand} />

        <Contents
          contents={rentedContentsExpired()}
          assetCategory={AssetCategory.MARKET_PLACE}
          title={t("rentedContentsExpired")}
          isPublic={true}
          container={"brandRental"}
        />

        <Contents
          contents={previousOwnedContents()}
          assetCategory={AssetCategory.MARKET_PLACE}
          title={t("previousOwnedContents")}
          isPublic={true}
          container={"brandSale"}
        />
      </div>
    </div>
  );
}
