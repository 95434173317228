// import moment from "moment";
import moment from "moment-timezone";

const currentTimezone = moment.tz.guess();

export const getDateStringFromISO = (isoDate: string, format: string) => {
  const numericMilliDate = () => {
    return isoDate ? Date.parse(isoDate) : 0;
  };
  return moment(numericMilliDate()).tz(currentTimezone).format(format);
};

export const getDateStringFromUnix = (timestamp: number, format: string) => {
  const numericMilliDate = () => {
    return timestamp ? timestamp * 1000 : 0;
  };
  return moment(numericMilliDate()).tz(currentTimezone).format(format);
};

export const isExpired = (expired_at: number) => {
  return moment().isAfter(expired_at);
};

export const isExpiredFromUnix = (expiryTimestamp: number) => {
  const expiry = moment.unix(expiryTimestamp);
  return moment().isAfter(expiry);
};
