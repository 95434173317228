import { merge } from "lodash";
import { config } from "src/config/config";
import { DRM_ACTIONS } from "src/actions/drm_actions";
import {
  AjaxStatus,
  APICallStatus,
  AssetMediaType,
  DRMSystem,
} from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import { encode } from "base64-arraybuffer";

const defaultState = {
  drmProbe: {
    status: { status: APICallStatus.INIT },
    support: {
      target: DRMSystem.NONE,
      wv: false,
      pr: false,
      fp: false,
    },
  },
  drmSystem: null,
  mediaType: null,
  drmToken: {
    status: { status: APICallStatus.INIT },
    token: null,
    mediaUrl: null,
    image: null,
    persistent: null,
    assetTokenId: null,
    serialNumber: null,
  },
  fpCert: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const drmReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DRM_ACTIONS.GET_DRM_TOKEN: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.GET_DRM_TOKEN: ", action.data);
      }

      let drmData = action.data.response;
      let mediaType = action.data.mediaType;
      if (mediaType === AssetMediaType.IMAGE) {
        let newState = {
          ...state,
          drmSystem: action.data.drmSystem,
          mediaType: mediaType,
          drmToken: {
            status: checkApiStatus(action.data.ajaxStat, null),
            image: drmData.image,
          },
        };
        return merge({}, state, newState);
      } else {
        let newState = {
          ...state,
          drmSystem: action.data.drmSystem,
          mediaType: mediaType,
          drmToken: {
            status: checkApiStatus(action.data.ajaxStat, null),
            mediaUrl: drmData.url,
            token: drmData.token,
            persistent: drmData.persistent,
            assetTokenId: drmData.asset_token_id,
            serialNumber: drmData.serial_number,
          },
        };
        return merge({}, state, newState);
      }
    }
    case DRM_ACTIONS.INIT_GET_DRM_TOKEN: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.INIT_GET_DRM_TOKEN: ", action.data);
      }
      return {
        ...state,
        drmToken: {
          status: { status: APICallStatus.INIT },
          mediaUrl: null,
          token: null,
          image: null,
          persistent: null,
          assetTokenId: null,
        },
      };
    }
    case DRM_ACTIONS.START_GET_DRM_TOKEN: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.START_GET_DRM_TOKEN: ", action.data);
      }
      return {
        ...state,
        drmToken: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case DRM_ACTIONS.START_DRM_PROBE: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.START_DRM_PROBE: ", action.data);
      }
      return {
        ...state,
        drmProbe: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case DRM_ACTIONS.SET_DRM_SUPPORT: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.SET_DRM_SUPPORT: ", action.data);
      }

      return {
        ...state,
        drmProbe: {
          status: { status: APICallStatus.SUCCEEDED },
          support: {
            target: action.data.target,
            wv: action.data.wv,
            pr: action.data.pr,
            fp: action.data.fp,
          },
        },
      };
    }
    case DRM_ACTIONS.GET_FAIRPLAY_CERT: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.GET_FAIRPLAY_CERT: ", action.data);
      }

      return {
        ...state,
        fpCert: {
          status: checkApiStatus(action.data.ajaxStat, "ok"),
          data:
            action.data.ajaxStat === AjaxStatus.DONE
              ? encode(action.data.response)
              : null,
        },
      };
    }
    case DRM_ACTIONS.INIT_GET_FAIRPLAY_CERT: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.INIT_GET_FAIRPLAY_CERT: ", action.data);
      }
      return {
        ...state,
        fpCert: {
          status: { status: APICallStatus.INIT },
          data: null,
        },
      };
    }
    case DRM_ACTIONS.START_GET_FAIRPLAY_CERT: {
      if (config.APP_DEBUG) {
        console.log("DRM_ACTIONS.START_GET_FAIRPLAY_CERT: ", action.data);
      }
      return {
        ...state,
        fpCert: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    default:
      return state;
  }
};
