import { GetRequest } from "src/utils/rest_api_util";
import { config } from "src/config/config";
import { isBlank } from "src/utils/validate_util";

export const getAllPublicAssetsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.listed_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    // Authorization: `Bearer ${options.accessToken}`
  };
  return GetRequest(url, data, headers);
};

export const getPublicAssetsPerSerialAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.listed_assets_per_serial(options.kid)}`;

  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }

  return GetRequest(url, null, headers);
};

export const getSaleAssetsByBrandAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.brand_sale_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    account: options.account,
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, data, headers);
};

export const getRentalAssetsByBrandAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.brand_rental_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    account: options.account,
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, data, headers);
};

export const getTradingAssetsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.trading_assets}`;
  const data: Record<string, any> = {
    // offset: Number(options.pageNumber) * Number(options.pageSize),
    trading_asset_token_ids: options.tradingAssetTokenIds,
    account: options.account,
    // limit: options.pageSize,
    // sort_by: options.sortBy,
  };
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, data, headers);
};

export const getTradableListedAssetsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.tradable_listed_assets}`;
  const data: Record<string, any> = {
    // offset: Number(options.pageNumber) * Number(options.pageSize),
    trading_asset_token_ids: options.tradingAssetTokenIds,
    account: options.account,
    // limit: options.pageSize,
    // sort_by: options.sortBy,
  };
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, data, headers);
};

export const getGroupsByBrandAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.brand_groups(options.account)}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, data, headers);
};

export const getNewReleaseAssetsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.listed_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    created_on_after: options.sortDateOnAfter,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    // Authorization: `Bearer ${options.accessToken}`
  };
  return GetRequest(url, data, headers);
};

export const getMostPopularAssetsAPI = (options: any) => {
  // TODO: Improve API url and parameters
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.listed_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    // Authorization: `Bearer ${options.accessToken}`
  };
  return GetRequest(url, data, headers);
};

export const getTopSalesAssetsAPI = (options: any) => {
  // TODO: Improve API url and parameters
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.listed_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    // Authorization: `Bearer ${options.accessToken}`
  };
  return GetRequest(url, data, headers);
};

export const getAssetsByCreatorAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.listed_created_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    creator_id: options.creatorUserID,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    // Authorization: `Bearer ${options.accessToken}`
  };
  return GetRequest(url, data, headers);
};

export const getPublicAssetDetailsAPI = (options: any) => {
  // TODO: Improve API url and parameters
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.listed_asset_by_id(options.assetTokenID)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getSpecificAssetDetailsAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.asset_by_id(
    options.assetKid
  )}?user_currency=${options.userCurrency}`;
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, null, headers);
};

export const getTradeListingAssetTokenIdAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.trade_listing_asset_token_id(
    options.assetKid
  )}`;
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, null, headers);
};

export const getAssetTransactionsAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.asset_transactions(options.assetTokenID)}`;
  const data = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: "transaction_timestamp DESC",
  };
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getAssetBidsAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.asset_bids(options.assetTokenID)}`;
  const data = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: "bid_timestamp DESC",
  };
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getAssetBidSummaryAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.asset_bid_summary(options.assetTokenID)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getAcquisitionRestrictionAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.acquisition_restriction}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    assetTokenId: options.assetTokenId,
  };

  return GetRequest(url, data, headers);
};
