export const featureFlags = {
  offlinePlayback: {
    isEnabled: false,
  },
  screening: {
    isEnabled: false,
  },
  trade: {
    isEnabled: false,
  },
};
