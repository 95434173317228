import { GetBinaryRequest, PostRequestJson } from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const getDRMTokenAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.drm_token}`;
  const data = {
    kid: options.assetKid,
  };
  if (options.persistent) {
    data["persistent"] = options.persistent;
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    drmType: options.drmSystem,
  };
  return PostRequestJson(url, data, headers);
};

export const getFairPlayCertAPI = () => {
  const url = `${config.DRM_CONFIG.FP_CERT_URL}`;
  return GetBinaryRequest(url);
};
