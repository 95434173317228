import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function CollectedContentCollectionLink(props) {
  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate("/account/collected");
  };

  return (
    <div className="hover:underline">
      <button className="flex items-center" onClick={() => onClickBackButton()}>
        <ChevronLeftIcon className="mr-2 inline h-4 w-4 text-gray-500" />
        マイコンテンツ
      </button>
    </div>
  );
}
