import { config } from "src/config/config";
import { PostRequestJson, PutRequestChunkFile } from "src/utils/rest_api_util";

enum UploadStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  FAILED = 4,
}

interface MPChunkStatus {
  status: UploadStatus;
  progress: number;
  retry: number;
}

interface MPUploadStatus {
  uploadId: string;
  fileSize: number;
  chunkSize: number;
  totalChunks: number;
  parallelChunks: number; // Number of parallel uploading chunks allowed
  activeChunks: number; // Number of chunks in progress
  completedChunks: number; // Number of completed chunks
  chunks: MPChunkStatus[];
  progress: number;
  status: UploadStatus;
  forceComplete: boolean; // force completion of the upload
}

const MP_UPLOAD_STAT = {};

export const mpUploadMediaAPI = async (options: any) => {
  initUpload(options);
  scheduleChunksForUpload(options);
};

export const mpCompleteUploadAPI = async (options: any) => {
  const upload = MP_UPLOAD_STAT[options.upload_id];
  if (!upload || upload.status === UploadStatus.COMPLETED) {
    return;
  }

  /*
   * scheduleChunksForUpload() will check the flag and complete the upload
   */
  upload.forceComplete = true;
};

const initUpload = (options: any) => {
  const chunkSize = config.MEDIA_UPLOAD_API.CHUNK_SIZE;
  let fileSize = options.file.size;
  let totalChunks = Math.ceil(fileSize / chunkSize);

  let status: MPUploadStatus = {
    uploadId: options.upload_id,
    fileSize: fileSize,
    chunkSize: chunkSize,
    totalChunks: totalChunks,
    parallelChunks: config.MEDIA_UPLOAD_API.PARALLEL_CHUNKS,
    activeChunks: 0,
    completedChunks: 0,
    chunks: [],
    progress: 0.0,
    status: UploadStatus.NOT_STARTED,
    forceComplete: false,
  };
  for (let i = 0; i < totalChunks; i++) {
    status.chunks.push({
      status: UploadStatus.NOT_STARTED,
      progress: 0.0,
      retry: 0,
    });
  }

  MP_UPLOAD_STAT[options.upload_id] = status;
};

const findNextChunkForUpload = (options: any): number => {
  const upload = MP_UPLOAD_STAT[options.upload_id];

  if (upload.completedChunks < upload.totalChunks &&
    upload.activeChunks < upload.parallelChunks) {

    for (let i = 0; i < upload.totalChunks; i++) {
      let chunkStat = upload.chunks[i];

      if (chunkStat.status === UploadStatus.NOT_STARTED) {
        chunkStat.status = UploadStatus.IN_PROGRESS;
        upload.activeChunks += 1;
        upload.status = UploadStatus.IN_PROGRESS;
        return i;
      } else if (chunkStat.status === UploadStatus.FAILED &&
          chunkStat.retry < config.MEDIA_UPLOAD_API.CHUNK_RETRY_COUNT) {
            chunkStat.status = UploadStatus.IN_PROGRESS;
            chunkStat.retry += 1;
            upload.activeChunks += 1;
            upload.status = UploadStatus.IN_PROGRESS;
            return i;
        }
    }
  }

  return upload.totalChunks;
};

const scheduleChunksForUpload = (options: any) => {
  const upload = MP_UPLOAD_STAT[options.upload_id];

  if (!upload || upload.status === UploadStatus.COMPLETED) {
    return;
  }

  if (upload.forceComplete) {
    const completeParams = {
      accessToken: options.accessToken,
      uploadId: options.upload_id,
    };
    completeUpload(completeParams).then(
      (result) => {
        if (options.onUploadSuccess) {
          options.onUploadSuccess();
        }

        if (options.upload_id in MP_UPLOAD_STAT) {
          if (config.APP_DEBUG) {
            console.log("Clear upload: %s", options.upload_id);
          }
          delete MP_UPLOAD_STAT[options.upload_id];
        }
      },
      (reason: string) => {
        if (options.onUploadFailed) {
          options.onUploadFailed("Failed to complete upload");
        }
      }
    );

    return;
  }

  // Schedule the chunks for uploading
  for (let i = 0; i < upload.parallelChunks; i++) {
    let chunkNum = findNextChunkForUpload(options);
    if (chunkNum < upload.totalChunks) {
      uploadChunk(options, chunkNum);
    }
  }
};

const uploadChunk = async (options: any, currentChunk: number) => {
  const upload = MP_UPLOAD_STAT[options.upload_id];

  if (config.APP_DEBUG) {
    console.log(
      "Upload chunk [%s]: [%d][%d]",
      options.upload_id,
      currentChunk + 1,
      upload.totalChunks
    );
  }

  let offset = currentChunk * upload.chunkSize;
  const chunkFile = options.file.slice(offset, offset + upload.chunkSize);
  const chunkNumber = currentChunk.toString();

  const chunkUrlParams = {
    accessToken: options.accessToken,
    uploadId: options.upload_id,
    chunkNumber: currentChunk.toString(),
  };
  getChunkUrl(chunkUrlParams).then(
    (chunkUrl) => {
      const uploadChunkParams = {
        chunkUrl: chunkUrl,
        chunkFile: chunkFile,
        uploadId: options.upload_id,
        chunkNumber: chunkNumber,
        uploadProgress: options.uploadProgress,
      };
      uploadChunkData(uploadChunkParams).then(
        (result) => {
          if (upload.completedChunks === upload.totalChunks) {
            const completeParams = {
              accessToken: options.accessToken,
              uploadId: options.upload_id,
            };
            completeUpload(completeParams).then(
              (result) => {
                if (options.uploadProgress) {
                  options.uploadProgress(100.0);
                }
                if (options.onUploadSuccess) {
                  options.onUploadSuccess();
                }

                if (options.upload_id in MP_UPLOAD_STAT) {
                  if (config.APP_DEBUG) {
                    console.log("Clear upload: %s", options.upload_id);
                  }
                  delete MP_UPLOAD_STAT[options.upload_id];
                }
              },
              (reason: string) => {
                if (options.onUploadFailed) {
                  options.onUploadFailed("Failed to complete upload");
                }
              }
            );
          } else {
            scheduleChunksForUpload(options);
          }
        },
        (reason: string) => {
          if (options.onUploadFailed) {
            options.onUploadFailed("Failed to upload chunk data");
          }

          scheduleChunksForUpload(options);
        }
      );
    },
    (reason: string) => {
      if (options.onUploadFailed) {
        options.onUploadFailed("Failed to get chunk URL");
      }

      scheduleChunksForUpload(options);
    }
  );
};

const getChunkUrl = async (options: any) => {
  const url = `${config.MEDIA_UPLOAD_API.HOST}${config.MEDIA_UPLOAD_API.APIS.asset_chunk_url}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const payload = {
    upload_id: options.uploadId,
    chunk_number: Number(options.chunkNumber) + 1,
  };
  const upload = MP_UPLOAD_STAT[options.uploadId];

  return new Promise((resolve, reject) => {
    PostRequestJson(url, payload, headers, null)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Got chunk URL: ", data["pre_signedUrl"]);
        }

        resolve(data["pre_signedUrl"]);
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting chunk URL");
        }

        upload.chunks[options.chunkNumber].status = UploadStatus.FAILED;
        upload.activeChunks -= 1;

        resolve(null);
      });
  });
};

const uploadChunkData = async (options: any) => {
  const upload: MPUploadStatus = MP_UPLOAD_STAT[options.uploadId];

  const onChunkProgress = (percent: number) => {
    if (config.APP_DEBUG) {
      console.log("Chunk progress: ", percent.toFixed(2));
    }

    let oldProgress = upload.chunks[options.chunkNumber].progress;
    upload.chunks[options.chunkNumber].progress = percent;
    upload.progress += (percent - oldProgress) / upload.totalChunks;

    if (options.uploadProgress) {
      options.uploadProgress(upload.progress);
    }
  };

  const progressCallback = {
    uploadProgress: onChunkProgress,
  };

  return new Promise((resolve, reject) => {
    PutRequestChunkFile(options.chunkUrl, options.chunkFile, {}, progressCallback, config.MEDIA_UPLOAD_API.CHUNK_UPLOAD_TIMEOUT)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Uploaded chunk file: ", data);
        }

        upload.chunks[options.chunkNumber].status = UploadStatus.COMPLETED;
        upload.activeChunks -= 1;
        upload.completedChunks += 1;

        resolve(true);
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in uploading chunk file");
        }

        upload.chunks[options.chunkNumber].status = UploadStatus.FAILED;
        upload.activeChunks -= 1;

        resolve(false);
      });
  });
};

const completeUpload = async (options: any) => {
  const upload: MPUploadStatus = MP_UPLOAD_STAT[options.uploadId];
  const url = `${config.MEDIA_UPLOAD_API.HOST}${config.MEDIA_UPLOAD_API.APIS.complete_upload}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const payload = {
    upload_id: options.uploadId,
  };

  return new Promise((resolve, reject) => {
    PostRequestJson(url, payload, headers, null)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Completed asset upload: ", data);
        }

        upload.status = UploadStatus.COMPLETED;

        resolve(true);
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in completing upload");
        }

        upload.status = UploadStatus.COMPLETED;

        resolve(false);
      });
  });
};
