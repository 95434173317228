import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutCurrentAccount } from "src/actions/account_actions";
import { showLoginModal } from "src/actions/ui/login_actions";
import { RootState } from "src/reducers/root_reducer";
import { isExpired } from "src/utils/date_util";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import Button from "src/components/elements/button";
import TextInput from "src/components/elements/text_input";
import HelperText from "src/components/elements/helper_text";
import { useTranslation } from "react-i18next";
import NotInitialized from "src/components/error/not_initialized";

export default function CouponsOccupationsNewPage() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );
  const authExpiresAt = useSelector(
    (state: RootState) => state.session.authExpiresAt
  );
  const accountInfo = useSelector(
    (state: RootState) => state.session.currentAccount.info
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("components/coupons/occupations/new");

  type FormValues = {
    couponCode: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // リダイレクト先でクーポンの適用を行う。
    navigate(`/login?code=${data.couponCode}`);
  };

  useEffect(() => {
    if (!isLoggedIn || isExpired(authExpiresAt)) {
      dispatch(logoutCurrentAccount());
      dispatch(showLoginModal(true));
    }
  }, [isLoggedIn, authExpiresAt, dispatch]);

  return (
    <div className="mx-auto max-w-2xl px-6 py-20">
      <div className="mb-8 text-2xl font-bold">{t("couponCodeEntry")}</div>
      <div>
        {isLoggedIn ? (
          accountInfo?.initialized ? (
            <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <TextInput
                  className="w-full"
                  type="text"
                  placeholder="ABCDEF123456"
                  isError={!!errors.couponCode}
                  blockSize="lg"
                  {...register("couponCode", {
                    required: {
                      value: true,
                      message: t("enterCouponCode"),
                    },
                  })}
                />
                <HelperText
                  className="mt-2"
                  isError={!!errors.couponCode}
                  size="sm"
                >
                  {errors.couponCode?.message}
                </HelperText>
              </div>
              <Button color="primary" size="sm" disabled={false}>
                {t("submit")}
              </Button>
            </form>
          ) : (
            <NotInitialized />
          )
        ) : (
          <div>{t("pleaseLogIn")}</div>
        )}
      </div>
    </div>
  );
}
