import { sampleSize } from "lodash";
import allNames from "./data/user_name_data.json";
import allCities from "./data/city_data.json";

export type MockPlayer = {
  id: number;
  name: string;
};

export type MockTeam = {
  id: number;
  name: string;
};

function genMockTeams(count: number) {
  let teams: MockTeam[] = [];

  if (!count || count > allCities.length) {
    return teams;
  }

  let cities = sampleSize(allCities, count);
  for (let i = 0; i < count; i++) {
    teams.push({
      id: i,
      name: cities[i].city_name + " Team",
    });
  }

  return teams;
}

function genMockPlayers(count: number) {
  let players: MockPlayer[] = [];

  if (!count || count > allNames.length) {
    return players;
  }

  let names = sampleSize(allNames, count);
  for (let i = 0; i < count; i++) {
    players.push({
      id: i,
      name: names[i].full_name,
    });
  }

  return players;
}

export { genMockTeams, genMockPlayers };
