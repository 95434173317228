import {
  GetRequest,
  PostRequestJson,
  PatchRequestJson,
} from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const createGroupAPI = (options: any) => {
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.create_group}`;
  const data: Record<string, any> = {
    name: options.name,
    description: options.description,
    brand_id: options.brandId,
    cover: options.coverImage,
    sale_starting_at: options.saleStartingAt,
  };

  return PostRequestJson(url, data, headers);
};

export const getGroupsAPI = (options: any) => {
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url: string = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.get_groups}`;

  return GetRequest(url, null, headers);
};

export const updateGroupAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.update_group(options.groupKey)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data: Record<string, any> = {
    name: options.name,
    description: options.description,
    cover: options.coverImage,
    brand_id: options.brandId,
    sale_starting_at: options.saleStartingAt,
    sale_ending_at: options.saleEndingAt,
    sorted_contents: options.sorted_contents,
  };
  return PatchRequestJson(url, data, headers);
};

export const getSpecificGroupDetailsAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.group_by_encrypted_id(
    options.group_encrypted_id
  )}?user_currency=${options.userCurrency}`;
  const headers = {};
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`;
  }
  return GetRequest(url, null, headers);
};

export const getContentsByUserAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
    group_encrypted_id: options.groupEncryptedId,
  };

  const url: string = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.contents_of_group(
    options.groupEncryptedId
  )}`;

  return GetRequest(url, null, headers);
};
