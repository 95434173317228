import { config } from "src/config/config";
import {
  createGroupAPI,
  getGroupsAPI,
  getContentsByUserAPI,
  updateGroupAPI,
  getSpecificGroupDetailsAPI,
} from "src/service/group_api";
import { AjaxStatus } from "src/service/api_types";

export const GROUP_ACTIONS = {
  //   set currentGroup
  SET_CURRENT_GROUP: "SET_CURRENT_GROUP",
  // create group
  INIT_CREATE_GROUP: "INIT_CREATE_GROUP",
  START_CREATE_GROUP: "START_CREATE_GROUP",
  CREATE_GROUP: "CREATE_GROUP",
  // get groups
  INIT_CREATED_GROUPS: "INIT_CREATED_GROUPS",
  START_CREATED_GROUPS: "START_CREATED_GROUPS",
  CREATED_GROUPS: "CREATED_GROUPS",
  // update group
  UPDATE_GROUP: "UPDATE_GROUP",
  INIT_UPDATE_GROUP: "INIT_UPDATE_GROUP",
  START_UPDATE_GROUP: "START_UPDATE_GROUP",
  // get group assets
  INIT_GET_CONTENTS_BY_USER: "INIT_GET_CONTENTS_BY_USER",
  START_GET_CONTENTS_BY_USER: "START_GET_CONTENTS_BY_USER",
  GET_CONTENTS_BY_USER: "GET_CONTENTS_BY_USER",
  // get specofic group
  INIT_SPECIFIC_GROUPS_DETAILS: "INIT_SPECIFIC_GROUPS_DETAILS",
  START_SPECIFIC_GROUPS_DETAILS: "START_SPECIFIC_GROUPS_DETAILS",
  GET_SPECIFIC_GROUPS_DETAILS: "GET_SPECIFIC_GROUPS_DETAILS",
};

export const initCreateGroup = () => ({
  type: GROUP_ACTIONS.INIT_CREATE_GROUP,
});

export const startCreateGroup = () => ({
  type: GROUP_ACTIONS.START_CREATE_GROUP,
});

export const createGroup = (data: any) => ({
  type: GROUP_ACTIONS.CREATE_GROUP,
  data,
});

export const initGetGroups = () => ({
  type: GROUP_ACTIONS.INIT_CREATED_GROUPS,
});

export const startGetGroups = () => ({
  type: GROUP_ACTIONS.START_CREATED_GROUPS,
});

export const createdGroups = (data: any) => ({
  type: GROUP_ACTIONS.CREATED_GROUPS,
  data,
});

export const updateGroup = (data: any) => ({
  type: GROUP_ACTIONS.UPDATE_GROUP,
  data,
});

export const initUpdateGroup = () => ({
  type: GROUP_ACTIONS.INIT_UPDATE_GROUP,
});

export const startUpdateGroup = () => ({
  type: GROUP_ACTIONS.START_UPDATE_GROUP,
});

export const initGetContentsByUser = () => ({
  type: GROUP_ACTIONS.INIT_GET_CONTENTS_BY_USER,
});

export const startGetContentsByUser = () => ({
  type: GROUP_ACTIONS.START_GET_CONTENTS_BY_USER,
});

export const getContentsByUser = (data: any) => ({
  type: GROUP_ACTIONS.GET_CONTENTS_BY_USER,
  data,
});

export const initSpecificGroupDetails = () => ({
  type: GROUP_ACTIONS.INIT_SPECIFIC_GROUPS_DETAILS,
});

export const startSpecificGroupDetails = () => ({
  type: GROUP_ACTIONS.START_SPECIFIC_GROUPS_DETAILS,
});

export const getSpecificGroupDetails = (data: any) => ({
  type: GROUP_ACTIONS.GET_SPECIFIC_GROUPS_DETAILS,
  data,
});

export const setCurrentGroup = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      public: params.public,
      category: params.category,
      group: params.group,
    };
    dispatch({
      type: GROUP_ACTIONS.SET_CURRENT_GROUP,
      data,
    });
  };
};

export const createGroupDispatch = (params: any) => (dispatch: Function) => {
  const options = {
    accessToken: params.accessToken,
    name: params.name,
    description: params.description,
    brandId: params.brandId,
    coverImage: params.coverImage,
    saleStartingAt: params.saleStartingAt,
  };

  dispatch(startCreateGroup());

  return createGroupAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Created group: ", data);
      }
      dispatch(
        createGroup({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in Creating group");
      }
      dispatch(
        createGroup({
          response: JSON.parse(err.responseText),
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const getGroupsDispatch = (params: any) => (dispatch: Function) => {
  const options = {
    accessToken: params.accessToken,
  };

  dispatch(startGetGroups());

  return getGroupsAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Received groups created by user: ", data);
      }
      const groupsData = {
        response: data,
        ajaxStat: AjaxStatus.DONE,
      };
      dispatch(createdGroups(groupsData));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting groups created by user");
      }
      const groupsData = {
        response: {},
        ajaxStat: AjaxStatus.FAILED,
      };
      dispatch(createdGroups(groupsData));
    });
};

export const getContentsByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      groupEncryptedId: params.groupEncryptedId,
    };

    dispatch(startGetContentsByUser());

    return getContentsByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received contents created by user: ", data);
        }
        let contentsData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getContentsByUser(contentsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting contents created by user");
        }
        let contentsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getContentsByUser(contentsData));
      });
  };

export const updateGroupDispatch = (params: any) => (dispatch: Function) => {
  const options = {
    accessToken: params.accessToken,
    groupKey: params.groupKey,
    name: params.name,
    description: params.description,
    brandId: params.brandId,
    coverImage: params.coverImage,
    saleStartingAt: params.saleStartingAt,
    saleEndingAt: params.saleEndingAt,
    sorted_contents: params.sorted_contents,
  };

  dispatch(startUpdateGroup());

  return updateGroupAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Updated group: ", data);
      }
      dispatch(
        updateGroup({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in updating asset");
      }
      dispatch(
        updateGroup({
          response: JSON.parse(err.responseText),
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const getSpecificGroupDetailsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      group_encrypted_id: params.group_encrypted_id,
      userCurrency: params.userCurrency,
    };

    dispatch(startSpecificGroupDetails());

    return getSpecificGroupDetailsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received public group details: ", data);
        }
        dispatch(
          getSpecificGroupDetails({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting public group details");
        }
        dispatch(
          getSpecificGroupDetails({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };
