import SparkMD5 from "spark-md5";
import { createHash } from "crypto";
import { config } from "src/config/config";

export async function md5FromFile(file) {
  let promise = new Promise((resolve, reject) => {
    var blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice;
    const chunkSize = config.MEDIA_UPLOAD_API.CHUNK_SIZE;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let fileReader = new FileReader();

    fileReader.onload = (event) => {
      if (config.APP_DEBUG) {
        console.log("read chunk nr", currentChunk + 1, "of", chunks);
      }
      spark.append(event.target.result);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNextChunk();
      } else {
        let md5 = spark.end();
        if (config.APP_DEBUG) {
          console.info("computed hash", md5);
        }
        resolve(md5);
      }
    };

    fileReader.onerror = function () {
      if (config.APP_DEBUG) {
        console.warn("Error, something went wrong.");
      }
      reject("");
    };

    function loadNextChunk() {
      let start = currentChunk * chunkSize;
      let end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNextChunk();
  });

  return await promise;
}

export async function base64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let data = reader.result.replace("data:", "").replace(/^.+,/, "");
      resolve(data);
    };
    reader.onerror = (error) => reject(error);
  });
}

export const sha1FromString = (data) => {
  const hash = createHash("sha1");
  hash.update(data);
  return hash.digest("hex");
};
