export const LOGIN_ACTIONS = {
  SHOW_LOGIN_MODAL: "SHOW_LOGIN_MODAL",
};

export const showLoginModal = (
  show: boolean,
  params: { nextPath?: string; fixed?: boolean } = {
    nextPath: "", // ログイン後のリダイレクト先
    fixed: false, // ×ボタンやモーダル外クリックによるモーダル離脱を禁止する
  }
) => {
  return async (dispatch: Function) => {
    const data = {
      show: show,
      nextPath: params.nextPath,
      fixed: params.fixed,
    };
    dispatch({
      type: LOGIN_ACTIONS.SHOW_LOGIN_MODAL,
      data,
    });
  };
};
