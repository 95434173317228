import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { sessionReducer } from "src/reducers/session_reducer";
import { publicAssetsReducer } from "src/reducers/public_assets_reducer";
import { userAssetsReducer } from "src/reducers/user_assets_reducer";
import { drmReducer } from "src/reducers/drm_reducer";
import { fundReducer } from "src/reducers/fund_reducer";
import { orderReducer } from "src/reducers/order_reducer";
import { assetFilterReducer } from "src/reducers/ui/asset_filter_reducer";
import { assetDetailsReducer } from "src/reducers/ui/asset_details_reducer";
import { creatorHomeReducer } from "src/reducers/ui/creator_home_reducer";
import { sportsReducer } from "src/reducers/sports_reducer";
import { accountHomeReducer } from "src/reducers/ui/account_home_reducer";
import { loginReducer } from "./ui/login_reducer";
import { notificationReducer } from "src/reducers/notification_reducer";
import { paymentReducer } from "src/reducers/payment_reducer";
import { userBrandsReducer } from "src/reducers/brands_reducer";
import { brandDetailsReducer } from "src/reducers/ui/brand_details_reducer";
import { userGroupsReducer } from "src/reducers/groups_reducer";
import { userDetailsReducer } from "src/reducers/user_details_reducer";

const RootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  session: sessionReducer,
  publicAssets: publicAssetsReducer,
  userAssets: userAssetsReducer,
  drm: drmReducer,
  fund: fundReducer,
  order: orderReducer,
  sports: sportsReducer,
  assetFilter: assetFilterReducer,
  assetDetails: assetDetailsReducer,
  creatorHome: creatorHomeReducer,
  accountHome: accountHomeReducer,
  loginModal: loginReducer,
  notifications: notificationReducer,
  payment: paymentReducer,
  userBrands: userBrandsReducer,
  brandDetails: brandDetailsReducer,
  userGroups: userGroupsReducer,
  userDetails: userDetailsReducer,
});
export default RootReducer;

export type RootState = ReturnType<typeof RootReducer>;
