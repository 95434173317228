import { merge } from "lodash";
import { config } from "src/config/config";
import { APICallStatus } from "src/service/api_types";
import { FLOW_ACTIONS } from "src/actions/flow_actions";
import { ACCOUNT_ACTIONS } from "src/actions/account_actions";
import { checkApiStatus } from "src/service/utils";
import moment from "moment";

const defaultState = {
  isLoggedIn: false,
  accessToken: "",
  authExpiresAt: null,
  currentAccount: {
    status: { status: APICallStatus.INIT },
    info: null,
  },
  fclCurrentUser: null,
  initAccount: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  updateAccount: {
    status: { status: APICallStatus.INIT },
  },
  confirmAccountInit: {
    status: { status: APICallStatus.INIT },
  },

  // 未使用
  userBids: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
};

export const sessionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FLOW_ACTIONS.FCL_LOGIN: {
      if (config.APP_DEBUG) {
        console.log("FLOW_ACTIONS.FCL_LOGIN: ", action.data);
      }

      let currentUser = action.data;
      let newState = {
        isLoggedIn: currentUser.loggedIn,
        accessToken: Buffer.from(currentUser.addr).toString("base64"),
        fclCurrentUser: currentUser,
        authExpiresAt: currentUser.expiresAt,
      };
      return merge({}, state, newState);
    }
    case FLOW_ACTIONS.FCL_LOGOUT: {
      if (config.APP_DEBUG) {
        console.log("FLOW_ACTIONS.FCL_LOGOUT: ", action.data);
      }

      let newState = {
        isLoggedIn: false,
        accessToken: "",
        authExpiresAt: null,
        currentAccount: {
          info: null,
          status: { status: APICallStatus.INIT },
        },
        fclCurrentUser: null,
        initAccount: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
        confirmAccountInit: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case ACCOUNT_ACTIONS.INIT_ACCOUNT_INIT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.INIT_ACCOUNT_INIT: ", action.data);
      }
      return {
        ...state,
        initAccount: {
          transaction: null,
          status: { status: APICallStatus.INIT },
        },
      };
    }
    case ACCOUNT_ACTIONS.START_ACCOUNT_INIT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.START_ACCOUNT_INIT: ", action.data);
      }
      return {
        ...state,
        initAccount: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ACCOUNT_ACTIONS.INITIALIZE_ACCOUNT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.INITIALIZE_ACCOUNT: ", action.data);
      }
      return {
        ...state,
        initAccount: {
          transaction: action.data,
          status: checkApiStatus(action.data.ajaxStat, null),
          errorCode: action.data.response,
        },
      };
    }
    case ACCOUNT_ACTIONS.INIT_CONFIRM_ACCOUNT_INIT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.INIT_CONFIRM_ACCOUNT_INIT: ", action.data);
      }
      return {
        ...state,
        confirmAccountInit: {
          status: { status: APICallStatus.INIT },
        },
      };
    }
    case ACCOUNT_ACTIONS.START_CONFIRM_ACCOUNT_INIT: {
      if (config.APP_DEBUG) {
        console.log(
          "ACCOUNT_ACTIONS.START_CONFIRM_ACCOUNT_INIT: ",
          action.data
        );
      }
      return {
        ...state,
        confirmAccountInit: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ACCOUNT_ACTIONS.CONFIRM_ACCOUNT_INIT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.CONFIRM_ACCOUNT_INIT: ", action.data);
      }
      if (action.data.response >= 500) {
        return {
          ...state,
          confirmAccountInit: {
            status: {
              status: APICallStatus.FAILED,
              errorCode: action.data.response,
            },
          },
        };
      } else {
        return {
          ...state,
          confirmAccountInit: {
            status: { status: APICallStatus.SUCCEEDED },
          },
        };
      }
    }
    case ACCOUNT_ACTIONS.INIT_GET_CURRENT_ACCOUNT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.INIT_GET_CURRENT_ACCOUNT: ", action.data);
      }
      // NOTE:
      //   本アクションでは取得済みの currentAccount.info があればそれを保持してください。
      //   取得済みの currentAccount.info を null にリセットしてしまうと
      //   portal/src/components/login/login_modal.tsx で自己 DDoS が発生します。
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          status: { status: APICallStatus.INIT },
        },
      };
    }
    case ACCOUNT_ACTIONS.START_GET_CURRENT_ACCOUNT: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.START_GET_CURRENT_ACCOUNT: ", action.data);
      }
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ACCOUNT_ACTIONS.GET_CURRENT_ACCOUNT_INFO: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.GET_CURRENT_ACCOUNT_INFO: ", action.data);
      }

      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          status: { status: APICallStatus.SUCCEEDED },
          info: action.data,
        },
      };
    }
    case ACCOUNT_ACTIONS.INIT_UPDATE_CURRENT_ACCOUNT: {
      if (config.APP_DEBUG) {
        console.log(
          "ACCOUNT_ACTIONS.INIT_UPDATE_CURRENT_ACCOUNT: ",
          action.data
        );
      }
      return {
        ...state,
        updateAccount: {
          status: { status: APICallStatus.INIT },
        },
      };
    }
    case ACCOUNT_ACTIONS.START_UPDATE_CURRENT_ACCOUNT: {
      if (config.APP_DEBUG) {
        console.log(
          "ACCOUNT_ACTIONS.START_UPDATE_CURRENT_ACCOUNT: ",
          action.data
        );
      }
      return {
        ...state,
        updateAccount: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ACCOUNT_ACTIONS.UPDATE_CURRENT_ACCOUNT_INFO: {
      if (config.APP_DEBUG) {
        console.log(
          "ACCOUNT_ACTIONS.UPDATE_CURRENT_ACCOUNT_INFO: ",
          action.data
        );
      }

      let status = checkApiStatus(action.data.ajaxStat, null);
      if (status.status === APICallStatus.SUCCEEDED) {
        return {
          ...state,
          updateAccount: {
            status: status,
          },
          currentAccount: {
            info: action.data,
            status: status,
          },
        };
      } else {
        return {
          ...state,
          updateAccount: {
            status: status,
          },
        };
      }
    }
    case ACCOUNT_ACTIONS.GET_USER_BIDS: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.GET_USER_BIDS: ", action.data);
      }

      let bids = action.data.response;
      return {
        ...state,
        userBids: {
          pageNumber:
            bids && bids.length
              ? action.data.pageNumber
              : state.userBids.pageNumber,
          data: bids,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case ACCOUNT_ACTIONS.INIT_GET_USER_BIDS: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.INIT_GET_USER_BIDS: ", action.data);
      }

      return {
        ...state,
        userBids: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
    }
    case ACCOUNT_ACTIONS.START_GET_USER_BIDS: {
      if (config.APP_DEBUG) {
        console.log("ACCOUNT_ACTIONS.START_GET_USER_BIDS: ", action.data);
      }

      return {
        ...state,
        userBids: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    default:
      return state;
  }
};
