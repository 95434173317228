import { ComponentProps } from "react";

type HelperTextProps = ComponentProps<"p"> & {
  isError?: boolean;

  // サイズの選択肢は tailwindcss の font size の種類に合わせている。
  size?: "sm" | "base";
};

// TextInput などの入力の助けとなるテキストやエラーメッセージのコンポーネント。
export default function HelperText({
  isError = false,
  size = "base",
  className,
  children,
  ...props
}: HelperTextProps) {
  const pClass = (() => {
    const baseClass = "text-sm";
    const colorClass = isError ? "text-red-500" : "text-gray-400";

    return [baseClass, colorClass, className].join(" ");
  })();

  return (
    <p className={pClass} {...props}>
      {children}
    </p>
  );
}
