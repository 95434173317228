import { GetRequest, PostRequestJson } from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const getUserNotificationsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_notifications}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const data = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
  };

  return GetRequest(url, data, headers);
};

export const getNotificationSummaryAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.notification_summary}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const readNotificationAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.read_notification(options.notificationId)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return PostRequestJson(url, {}, headers);
};
