import locale_en from "src/i18l/locales/en.json";
import locale_es from "src/i18l/locales/es.json";
import locale_fr from "src/i18l/locales/fr.json";
import locale_it from "src/i18l/locales/it.json";
import locale_zh from "src/i18l/locales/zh.json";

export const LocaleData = {
  en: locale_en,
  es: locale_es,
  fr: locale_fr,
  it: locale_it,
  zh: locale_zh,
};
