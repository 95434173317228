import { config } from "src/config/config";
import { USER_DETAILS_ACTIONS } from "src/actions/user_details_actions";

const defaultState = {
  userCurrency: null,
};

export const userDetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_DETAILS_ACTIONS.SET_USER_CURRENCY: {
      if (config.APP_DEBUG) {
        console.log("USER_DETAILS_ACTIONS.SET_USER_CURRENCY: ", action.data);
      }

      if (!action.data) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          userCurrency: action.data,
        };
      }
    }
    default:
      return state;
  }
};
