import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function MyAssetsRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/account/collected");
  });

  return <div />;
}
