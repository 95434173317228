import { config } from "src/config/config";
import { SPORTS_ACTIONS } from "src/actions/sports_actions";
import { APICallStatus } from "src/service/api_types";

const defaultState = {
  allTeams: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  allPlayers: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  playersPerTeam: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const sportsReducer = (state: any = defaultState, action: any) => {
  switch (action.type) {
    case SPORTS_ACTIONS.GET_ALL_TEAMS: {
      if (config.APP_DEBUG) {
        console.log("SPORTS_ACTIONS.GET_ALL_TEAMS: ", action.data);
      }

      let teams = action.data.response;
      return {
        ...state,
        allTeams: {
          data: teams,
          status: APICallStatus.SUCCEEDED,
        },
      };
    }
    case SPORTS_ACTIONS.GET_ALL_PLAYERS: {
      if (config.APP_DEBUG) {
        console.log("SPORTS_ACTIONS.GET_ALL_PLAYERS: ", action.data);
      }

      let players = action.data.response;
      return {
        ...state,
        allPlayers: {
          data: players,
          status: APICallStatus.SUCCEEDED,
        },
      };
    }
    case SPORTS_ACTIONS.GET_PLAYERS_PER_TEAM: {
      if (config.APP_DEBUG) {
        console.log("SPORTS_ACTIONS.GET_PLAYERS_PER_TEAM: ", action.data);
      }

      let players = action.data.response;
      return {
        ...state,
        playersPerTeam: {
          data: players,
          status: APICallStatus.SUCCEEDED,
        },
      };
    }
    default:
      return state;
  }
};
