import { GetRequest } from "src/utils/rest_api_util";
import { config } from "src/config/config";
import { Content } from "./content_api";

// service/app/views/owned_contents/brands/index.json.jbuilder に対応する型
export type OwnedContentBrands = Array<OwnedContentBrand>;

// service/app/views/owned_contents/brands/show.json.jbuilder に対応する型
export type OwnedContentBrand = {
  key: string;
  account: string;
  name: string;
  hero_image_url: string | null;
  contents: Array<Content>;
  groups: Array<OwnedContentBrandGroup>;
};

// service/app/views/owned_contents/groups/index.json.jbuilder に対応する型
export type OwnedContentBrandGroups = Array<OwnedContentBrandGroup>;

// service/app/views/owned_contents/groups/show.json.jbuilder に対応する型
export type OwnedContentBrandGroup = {
  key: string;
  name: string;
  cover_url: string | null;
  contents: Array<Content>;
};

export const getOwnedContentBrandsAPI = ({
  accessToken,
}: {
  accessToken: string;
}) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.owned_content_brands}`;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getOwnedContentBrandAPI = ({
  accessToken,
  key,
}: {
  accessToken: string;
  key: string;
}) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.owned_content_brand(key)}`;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return GetRequest(url, null, headers);
};
