import React from "react";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "src/components/router";

const AppProvider = ({ store, locale, messages }) => {
  return (
    <Provider store={store.store}>
      <IntlProvider locale={locale} messages={messages}>
        <PersistGate loading={null} persistor={store.persistor}>
          <AppRouter />
        </PersistGate>
      </IntlProvider>
    </Provider>
  );
};

export default AppProvider;
