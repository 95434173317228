import { getBrandDetailsAPI } from "src/service/brand_asset_api";
import { AjaxStatus } from "src/service/api_types";
import { config } from "src/config/config";

export const BRAND_DETAILS_ACTIONS = {
  SET_CURRENT_BRAND: "SET_CURRENT_BRAND",
  // 以下 3 行は GET /brand/:account に使用する
  INIT_BRAND_DETAILS: "INIT_BRAND_DETAILS",
  START_BRAND_DETAILS: "START_BRAND_DETAILS",
  GET_BRAND_DETAILS: "GET_BRAND_DETAILS",
};

// 以下 3 つの initBrandDetails, startBrandDetails, getBrandDetails は GET /brand/:account に使用する
export const initBrandDetails = () => ({
  type: BRAND_DETAILS_ACTIONS.INIT_BRAND_DETAILS,
});

export const startBrandDetails = () => ({
  type: BRAND_DETAILS_ACTIONS.START_BRAND_DETAILS,
});

export const getBrandDetails = (data: any) => ({
  type: BRAND_DETAILS_ACTIONS.GET_BRAND_DETAILS,
  data,
});

export const setCurrentBrand = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      brand: params.brand,
    };
    dispatch({
      type: BRAND_DETAILS_ACTIONS.SET_CURRENT_BRAND,
      data,
    });
  };
};

export const getBrandDetailsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      account: params.account,
    };

    dispatch(startBrandDetails());

    return getBrandDetailsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received brand detail: ", data);
        }
        let brandsData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        console.log("getBrandDetailsAPI", brandsData);
        dispatch(getBrandDetails(brandsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting brand detail");
        }
        let brandsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getBrandDetails(brandsData));
      });
  };
