import Group from "src/components/collected_contents/brands/detail/group";
import { OwnedContentBrand } from "src/service/owned_content_brand_api";

export default function GroupCollection({
  brand,
}: {
  brand: OwnedContentBrand;
}) {
  const groups = brand.groups;

  return (
    <div className="space-y-4">
      {groups.map((group) => {
        return <Group key={group.key} group={group} />;
      })}
    </div>
  );
}
