import { merge } from "lodash";
import { config } from "src/config/config";
import { FUND_ACTIONS } from "src/actions/fund_actions";
import { APICallStatus } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import moment from "moment";

const defaultState = {
  depositUsdcStatus: { status: APICallStatus.INIT },
  depositCashStatus: { status: APICallStatus.INIT },
  cashBalance: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  userTrans: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
};

export const fundReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FUND_ACTIONS.DEPOSIT_USDC: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.DEPOSIT_USDC: ", action.data);
      }

      let newState = {
        depositUsdcStatus: {
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.INIT_DEPOSIT_USDC: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.INIT_DEPOSIT_USDC: ", action.data);
      }

      let newState = {
        depositUsdcStatus: {
          status: APICallStatus.INIT,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.START_DEPOSIT_USDC: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.START_DEPOSIT_USDC: ", action.data);
      }

      let newState = {
        depositUsdcStatus: {
          status: APICallStatus.IN_PROGRESS,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.DEPOSIT_CASH: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.DEPOSIT_CASH: ", action.data);
      }

      let newState = {
        depositCashStatus: {
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.INIT_DEPOSIT_CASH: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.INIT_DEPOSIT_CASH: ", action.data);
      }

      let newState = {
        depositCashStatus: {
          status: APICallStatus.INIT,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.START_DEPOSIT_CASH: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.START_DEPOSIT_CASH: ", action.data);
      }

      let newState = {
        depositCashStatus: {
          status: APICallStatus.IN_PROGRESS,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.GET_CASH_BALANCE: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.GET_CASH_BALANCE: ", action.data);
      }

      let newState = {
        cashBalance: {
          status: checkApiStatus(action.data.ajaxStat, null),
          data: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.INIT_GET_CASH_BALANCE: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.INIT_GET_CASH_BALANCE: ", action.data);
      }

      let newState = {
        cashBalance: {
          status: APICallStatus.INIT,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.START_GET_CASH_BALANCE: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.START_GET_CASH_BALANCE: ", action.data);
      }

      let newState = {
        cashBalance: {
          status: APICallStatus.IN_PROGRESS,
        },
      };
      return merge({}, state, newState);
    }
    case FUND_ACTIONS.GET_USER_TRANSACTIONS: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.GET_USER_TRANSACTIONS: ", action.data);
      }

      let trans = action.data.response;
      return {
        ...state,
        userTrans: {
          pageNumber:
            trans && trans.length
              ? action.data.pageNumber
              : state.userTrans.pageNumber,
          data: trans,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case FUND_ACTIONS.INIT_GET_USER_TRANSACTIONS: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.INIT_GET_USER_TRANSACTIONS: ", action.data);
      }

      return {
        ...state,
        userTrans: {
          status: APICallStatus.INIT,
          data: [],
        },
      };
    }
    case FUND_ACTIONS.START_GET_USER_TRANSACTIONS: {
      if (config.APP_DEBUG) {
        console.log("FUND_ACTIONS.START_GET_USER_TRANSACTIONS: ", action.data);
      }

      return {
        ...state,
        userTrans: {
          status: APICallStatus.IN_PROGRESS,
        },
      };
    }
    default:
      return state;
  }
};
