import { config } from "src/config/config";
import {
  confirmAccountInitAPI,
  getCurrentAccountInfoAPI,
  getUserBidsAPI,
  initializeAccountAPI,
  updateCurrentAccountInfoAPI,
} from "src/service/account_api";
import { AjaxStatus } from "src/service/api_types";
import { fclLogout } from "./flow_actions";

export const ACCOUNT_ACTIONS = {
  INIT_GET_CURRENT_ACCOUNT: "INIT_GET_CURRENT_ACCOUNT",
  START_GET_CURRENT_ACCOUNT: "START_GET_CURRENT_ACCOUNT",
  GET_CURRENT_ACCOUNT_INFO: "GET_CURRENT_ACCOUNT_INFO",
  INIT_UPDATE_CURRENT_ACCOUNT: "INIT_UPDATE_CURRENT_ACCOUNT",
  START_UPDATE_CURRENT_ACCOUNT: "START_UPDATE_CURRENT_ACCOUNT",
  UPDATE_CURRENT_ACCOUNT_INFO: "UPDATE_CURRENT_ACCOUNT_INFO",
  INIT_ACCOUNT_INIT: "INIT_ACCOUNT_INIT",
  START_ACCOUNT_INIT: "START_INIT_ACCOUNT",
  INITIALIZE_ACCOUNT: "INITIALIZE_ACCOUNT",
  INIT_CONFIRM_ACCOUNT_INIT: "INIT_CONFIRM_ACCOUNT_INIT",
  START_CONFIRM_ACCOUNT_INIT: "START_CONFIRM_ACCOUNT_INIT",
  CONFIRM_ACCOUNT_INIT: "CONFIRM_ACCOUNT_INIT",
  GET_USER_BIDS: "GET_USER_BIDS",
  INIT_GET_USER_BIDS: "INIT_GET_USER_BIDS",
  START_GET_USER_BIDS: "START_GET_USER_BIDS",
};

export const initGetCurrentAccount = () => ({
  type: ACCOUNT_ACTIONS.INIT_GET_CURRENT_ACCOUNT,
});

export const startGetCurrentAccount = () => ({
  type: ACCOUNT_ACTIONS.START_GET_CURRENT_ACCOUNT,
});

export const getCurrentAccountInfo = (data: any) => ({
  type: ACCOUNT_ACTIONS.GET_CURRENT_ACCOUNT_INFO,
  data,
});

export const getCurrentAccountInfoDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      userCurrency: params.userCurrency,
    };

    dispatch(startGetCurrentAccount());

    return getCurrentAccountInfoAPI(options)
      .done((data) => {
        if (config.APP_DEBUG) {
          console.log("Received current account info: ", data);
        }
        dispatch(getCurrentAccountInfo(data));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting current account info");
        }
      });
  };

export const initAccountInit = () => ({
  type: ACCOUNT_ACTIONS.INIT_ACCOUNT_INIT,
});

export const startAccountInit = () => ({
  type: ACCOUNT_ACTIONS.START_ACCOUNT_INIT,
});

export const initializeAccount = (data: any) => ({
  type: ACCOUNT_ACTIONS.INITIALIZE_ACCOUNT,
  data,
});

export const initializeAccountDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
    };

    dispatch(startAccountInit());

    return initializeAccountAPI(options)
      .done((data) => {
        if (config.APP_DEBUG) {
          console.log("Received init account transaction: ", data);
        }
        dispatch(initializeAccount(data));
      })
      .fail((err) => {
        if (err.status >= 500) {
          if (config.APP_DEBUG) {
            console.log("InternalServerError in accessing to API");
          }
          dispatch(
            initializeAccount({
              response: err.status,
              ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
            })
          );
        } else {
          if (config.APP_DEBUG) {
            console.error("Error in initializing account");
          }
        }
      });
  };

export const initConfirmAccountInit = () => ({
  type: ACCOUNT_ACTIONS.INIT_CONFIRM_ACCOUNT_INIT,
});

export const startConfirmAccountInit = () => ({
  type: ACCOUNT_ACTIONS.START_CONFIRM_ACCOUNT_INIT,
});

export const confirmAccountInit = (data: any) => ({
  type: ACCOUNT_ACTIONS.CONFIRM_ACCOUNT_INIT,
  data,
});

export const confirmAccountInitDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      email: params.fclCurrentUser.services[0].scoped.email,
    };

    dispatch(startConfirmAccountInit());

    return confirmAccountInitAPI(options)
      .done((data) => {
        if (config.APP_DEBUG) {
          console.log("Received confirm account response: ", data);
        }
        dispatch(confirmAccountInit(data));
      })
      .fail((err) => {
        if (err.status >= 500) {
          if (config.APP_DEBUG) {
            console.log("InternalServerError in accessing to API");
          }
          dispatch(
            confirmAccountInit({
              response: err.status,
              ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
            })
          );
        } else {
          if (config.APP_DEBUG) {
            console.error("Error in confirming account init");
          }
        }
      });
  };

export const logoutCurrentAccount = () => (dispatch: Function) => {
  if (config.APP_DEBUG) {
    console.error("Logout current account");
  }

  if (config.AUTH_WITH_FCL) {
    dispatch(fclLogout());
  }
};

export const initUpdateCurrentAccount = () => ({
  type: ACCOUNT_ACTIONS.INIT_UPDATE_CURRENT_ACCOUNT,
});

export const startUpdateCurrentAccount = () => ({
  type: ACCOUNT_ACTIONS.START_UPDATE_CURRENT_ACCOUNT,
});

export const updateCurrentAccountInfo = (data: any) => ({
  type: ACCOUNT_ACTIONS.UPDATE_CURRENT_ACCOUNT_INFO,
  data,
});

export const updateCurrentAccountDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      username: params.username,
      email: params.email,
      firstName: params.firstName,
      lastName: params.lastName,
    };

    dispatch(startUpdateCurrentAccount());

    return updateCurrentAccountInfoAPI(options)
      .done((data) => {
        if (config.APP_DEBUG) {
          console.log("Received updated account info: ", data);
        }
        dispatch(updateCurrentAccountInfo(data));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in updating current account info");
        }
      });
  };

export const getUserBids = (data: any) => ({
  type: ACCOUNT_ACTIONS.GET_USER_BIDS,
  data,
});

export const initGetUserBids = () => ({
  type: ACCOUNT_ACTIONS.INIT_GET_USER_BIDS,
});

export const startGetUserBids = () => ({
  type: ACCOUNT_ACTIONS.START_GET_USER_BIDS,
});

export const getUserBidsDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
  };

  dispatch(startGetUserBids());

  return getUserBidsAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Received user bids: ", data);
      }
      let bidsData = {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        response: data,
        ajaxStat: AjaxStatus.DONE,
      };
      dispatch(getUserBids(bidsData));
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in getting user bids");
      }
      dispatch(
        getUserBids({
          response: [],
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};
