import { config } from "src/config/config";
import { APICallStatus } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import moment from "moment";
import { merge } from "lodash";
import { NOTIFICATION_ACTIONS } from "src/actions/ui/notification_actions";

const defaultState = {
  userNotifications: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  notificationSummary: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const notificationReducer = (state = defaultState, action): any => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.GET_USER_NOTIFICATIONS: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.GET_USER_NOTIFICATIONS: ",
          action.data
        );
      }

      let notifications = action.data.response;
      return {
        ...state,
        userNotifications: {
          pageNumber:
            notifications && notifications.length
              ? action.data.pageNumber
              : state.userNotifications.pageNumber,
          data: notifications,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case NOTIFICATION_ACTIONS.INIT_GET_USER_NOTIFICATIONS: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.INIT_GET_USER_NOTIFICATIONS: ",
          action.data
        );
      }

      let newState = {
        userNotifications: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case NOTIFICATION_ACTIONS.START_GET_USER_NOTIFICATIONS: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.START_GET_USER_NOTIFICATIONS: ",
          action.data
        );
      }

      let newState = {
        ...state,
        userNotifications: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case NOTIFICATION_ACTIONS.GET_NOTIFICATION_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.GET_NOTIFICATION_SUMMARY: ",
          action.data
        );
      }

      let summary = action.data.response;
      return {
        ...state,
        notificationSummary: {
          data: summary,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case NOTIFICATION_ACTIONS.INIT_GET_NOTIFICATION_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.INIT_GET_NOTIFICATION_SUMMARY: ",
          action.data
        );
      }

      let newState = {
        notificationSummary: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case NOTIFICATION_ACTIONS.START_GET_NOTIFICATION_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "NOTIFICATION_ACTIONS.START_GET_NOTIFICATION_SUMMARY: ",
          action.data
        );
      }

      let newState = {
        ...state,
        notificationSummary: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
