import { merge } from "lodash";
import { config } from "src/config/config";
import { ASSET_DETAILS_ACTIONS } from "src/actions/ui/asset_details";
import { APICallStatus, AssetCategory } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import moment from "moment";

const defaultState = {
  isPublic: true,
  category: AssetCategory.MARKET_PLACE,
  currentAsset: null,
  creatorInfo: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  ownerInfo: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  assetTrans: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  assetBids: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  assetBidSummary: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const assetDetailsReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ASSET_DETAILS_ACTIONS.SET_CURRENT_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.SET_CURRENT_ASSET: ", action.data);
      }

      if (!action.data.asset) {
        return {
          ...state,
          isPublic: action.data.public,
          category: action.data.category,
        };
      } else {
        return {
          ...state,
          isPublic: action.data.public,
          category: action.data.category,
          currentAsset: action.data.asset,
        };
      }
    }
    case ASSET_DETAILS_ACTIONS.INIT_GET_CREATOR_INFO: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.INIT_GET_CREATOR_INFO: ",
          action.data
        );
      }

      let newState = {
        creatorInfo: {
          status: { status: APICallStatus.INIT },
          data: null,
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.START_GET_CREATOR_INFO: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.START_GET_CREATOR_INFO: ",
          action.data
        );
      }

      let newState = {
        creatorInfo: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.GET_CREATOR_INFO: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.GET_CREATOR_INFO: ", action.data);
      }

      let info = action.data.response;
      let newState = {
        creatorInfo: {
          status: { status: APICallStatus.SUCCEEDED },
          data: info,
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.INIT_GET_OWNER_INFO: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.INIT_GET_OWNER_INFO: ", action.data);
      }

      let newState = {
        ownerInfo: {
          status: { status: APICallStatus.INIT },
          data: null,
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.START_GET_OWNER_INFO: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.START_GET_OWNER_INFO: ",
          action.data
        );
      }

      let newState = {
        ownerInfo: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.GET_OWNER_INFO: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.GET_OWNER_INFO: ", action.data);
      }

      let info = action.data.response;
      let newState = {
        ownerInfo: {
          status: { status: APICallStatus.SUCCEEDED },
          data: info,
        },
      };
      return merge({}, state, newState);
    }
    case ASSET_DETAILS_ACTIONS.GET_ASSET_TRANSACTION: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.GET_ASSET_TRANSACTION: ",
          action.data
        );
      }

      let trans = action.data.response;
      return {
        ...state,
        assetTrans: {
          pageNumber:
            trans && trans.length
              ? action.data.pageNumber
              : state.assetTrans.pageNumber,
          data: trans,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_TRANSACTION: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_TRANSACTION: ",
          action.data
        );
      }

      return {
        ...state,
        assetTrans: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.START_GET_ASSET_TRANSACTION: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.START_GET_ASSET_TRANSACTION: ",
          action.data
        );
      }

      return {
        ...state,
        assetTrans: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.GET_ASSET_BIDS: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.GET_ASSET_BIDS: ", action.data);
      }

      let bids = action.data.response;
      return {
        ...state,
        assetBids: {
          pageNumber:
            bids && bids.length
              ? action.data.pageNumber
              : state.assetBids.pageNumber,
          data: bids,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BIDS: {
      if (config.APP_DEBUG) {
        console.log("ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BIDS: ", action.data);
      }

      return {
        ...state,
        assetBids: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.START_GET_ASSET_BIDS: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.START_GET_ASSET_BIDS: ",
          action.data
        );
      }

      return {
        ...state,
        assetBids: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.GET_ASSET_BID_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.GET_ASSET_BID_SUMMARY: ",
          action.data
        );
      }

      let bidSummary = action.data.response;
      return {
        ...state,
        assetBidSummary: {
          data: bidSummary,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BID_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.INIT_GET_ASSET_BID_SUMMARY: ",
          action.data
        );
      }

      return {
        ...state,
        assetBidSummary: {
          status: { status: APICallStatus.INIT },
          data: null,
        },
      };
    }
    case ASSET_DETAILS_ACTIONS.START_GET_ASSET_BID_SUMMARY: {
      if (config.APP_DEBUG) {
        console.log(
          "ASSET_DETAILS_ACTIONS.START_GET_ASSET_BID_SUMMARY: ",
          action.data
        );
      }

      return {
        ...state,
        assetBidSummary: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
    }
    default:
      return state;
  }
};
