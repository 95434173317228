import { APICallStatus } from "src/service/api_types";
import { GROUP_ACTIONS } from "src/actions/group_actions";
import { config } from "src/config/config";
import { checkApiStatus } from "src/service/utils";
import { merge } from "lodash";

const defaultState = {
  currentGroup: null,
  createGroup: {
    status: { status: APICallStatus.INIT },
    response: null,
  },
  createdGroups: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  updateGroup: {
    status: { status: APICallStatus.INIT },
    response: null,
  },
  groupDetail: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  contents: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const userGroupsReducer = (state = defaultState, action) => {
  switch (action.type) {
    // set current group
    case GROUP_ACTIONS.SET_CURRENT_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.SET_CURRENT_GROUP: ", action.data);
      }

      if (!action.data.group) {
        return {
          ...state,
          isPublic: action.data.public,
          category: action.data.category,
        };
      } else {
        return {
          ...state,
          isPublic: action.data.public,
          category: action.data.category,
          currentGroup: action.data.group,
        };
      }
    }
    // create group
    case GROUP_ACTIONS.CREATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.CREATE_GROUP: ", action.data);
      }

      let newState = {
        createGroup: {
          status: checkApiStatus(action.data.ajaxStat, null),
          response: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.INIT_CREATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.INIT_CREATE_GROUP: ", action.data);
      }

      let newState = {
        createGroup: {
          status: { status: APICallStatus.INIT },
          response: null,
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.START_CREATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.START_CREATE_GROUP: ", action.data);
      }

      let newState = {
        createGroup: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    // get group
    case GROUP_ACTIONS.CREATED_GROUPS: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.CREATED_GROUPS: ", action.data);
      }

      let groups = action.data.response;
      return {
        ...state,
        createdGroups: {
          data: groups,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case GROUP_ACTIONS.INIT_CREATED_GROUPS: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.INIT_CREATED_GROUPS: ", action.data);
      }

      let newState = {
        createdGroups: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.START_CREATED_GROUPS: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.START_CREATED_GROUPS: ", action.data);
      }

      let newState = {
        createdGroups: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }

    // get specific group
    case GROUP_ACTIONS.GET_SPECIFIC_GROUPS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.GET_SPECIFIC_GROUPS_DETAILS: ", action.data);
      }

      let group = action.data.response;
      let newState = {
        groupDetail: {
          data: group,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.INIT_SPECIFIC_GROUPS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "GROUP_ACTIONS.INIT_SPECIFIC_GROUPS_DETAILS: ",
          action.data
        );
      }

      let newState = {
        groupDetail: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }

    case GROUP_ACTIONS.START_SPECIFIC_GROUPS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "GROUP_ACTIONS.START_SPECIFIC_GROUPS_DETAILS: ",
          action.data
        );
      }

      let newState = {
        groupDetail: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }

    // update group
    case GROUP_ACTIONS.UPDATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.UPDATE_GROUP: ", action.data);
      }

      let newState = {
        updateGroup: {
          status: checkApiStatus(action.data.ajaxStat, null),
          response: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.INIT_UPDATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.INIT_UPDATE_GROUP: ", action.data);
      }

      let newState = {
        updateGroup: {
          status: { status: APICallStatus.INIT },
          response: null,
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.START_UPDATE_GROUP: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.START_UPDATE_GROUP: ", action.data);
      }

      let newState = {
        updateGroup: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    // get contents
    case GROUP_ACTIONS.GET_CONTENTS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.GET_CONTENTS_BY_USER: ", action.data);
      }

      let contents = action.data.response;
      return {
        ...state,
        contents: {
          data: contents,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case GROUP_ACTIONS.INIT_GET_CONTENTS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.INIT_GET_CONTENTS_BY_USER: ", action.data);
      }

      let newState = {
        contents: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case GROUP_ACTIONS.START_GET_CONTENTS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("GROUP_ACTIONS.START_GET_CONTENTS_BY_USER: ", action.data);
      }

      let newState = {
        contents: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }

    default:
      return state;
  }
};
