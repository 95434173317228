export const CREATOR_HOME_ACTIONS = {
  SET_CREATOR_INFO: "SET_CREATOR_INFO",
  SWITCH_CREATOR_HOME_TAB: "SWITCH_CREATOR_HOME_TAB",
};

export const setCreatorInfo = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      creatorInfo: params.creatorInfo,
    };
    dispatch({
      type: CREATOR_HOME_ACTIONS.SET_CREATOR_INFO,
      data,
    });
  };
};

export const switchCreatorHomeTab = (params: any) => {
  return async (dispatch: Function) => {
    let data = {
      activeTab: params.activeTab,
      tabSwitched: params.tabSwitched,
    };
    dispatch({
      type: CREATOR_HOME_ACTIONS.SWITCH_CREATOR_HOME_TAB,
      data,
    });
  };
};
