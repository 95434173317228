export enum UserRole {
  STANDARD = "STANDARD_USER",
  ORH = "ORH",
  ADMIN = "ADMIN",
}

export enum AssetMediaType {
  IMAGE = "ASSET_IMAGE",
  VIDEO = "ASSET_VIDEO",
  AUDIO = "ASSET_AUDIO",
  UNKNOWN = "ASSET_UNKNOWN",
}

export enum AssetBusinessCond {
  SALE = "sale",
  RENT = "rent",
  AUCTION = "auction",
  TRANSFER = "transfer",
  BOTH = "both", // MVP 1.0
  UNKNOWN = "unknown",
}

export enum NotificationType {
  AUCTION_WIN = 1,
}

export enum NotificationStatus {
  NEW = 1,
  READ = 2,
  DELETE = 3,
}

export enum AssetType {
  PUBLIC = "public",
  PRIVATE = "private",
}

export enum AssetTokenType {
  ASSET = "asset",
  RENT = "rent",
}

export enum AssetCategory {
  TOP_SALES = 0,
  MOST_POPULAR = 1,
  NEW_RELEASE = 2,
  MARKET_PLACE = 3,
  COLLECTED = 4,
  RENTED = 5,
  CREATED = 6,
  FAVORITED = 7,
  CREATORS = 8,
  OWNERS = 9,
  USER_BIDS = 5,
}

export enum DRMSystem {
  NONE = "non",
  PLAYREADY = "pr",
  WIDEVINE = "wv",
  FAIRPLAY = "fp",
}

export enum DRMKeySystem {
  CLEARKEY = "org.w3.clearkey",
  PLAYREADY = "com.microsoft.playready",
  PR_RECOMM = "com.microsoft.playready.recommendation",
  WIDEVINE = "com.widevine.alpha",
  FAIRPLAY = "com.apple.fps",
  FAIRPLAY_10 = "com.apple.fps.1_0",
  FAIRPLAY_20 = "com.apple.fps.2_0",
  FAIRPLAY_30 = "com.apple.fps.3_0",
}

export enum APICallStatus {
  INIT = 0,
  IN_PROGRESS = 1,
  SUCCEEDED = 2,
  FAILED = 3,
}

export enum AjaxStatus {
  UNKNOWN = 0,
  DONE = 1,
  FAILED = 2,
  UNPROCESSABLE_ENTITY = 3,
  INTERNAL_SERVER_ERROR = 4,
  FORBIDDEN = 5,
}

// FlowTxStatusObject の内、Roadstead で必要な情報のみを抽出した型。
export type TxStatusObject = {
  status: FlowTxStatusObject["status"];
  statusCode: FlowTxStatusObject["statusCode"];
  errorMessage?: FlowTxStatusObject["errorMessage"];
};

// Flow の TransactionStatusObject に対応している。
// https://developers.flow.com/tools/clients/fcl-js/api#transactionstatusobject
export type FlowTxStatusObject = {
  blockId: string;
  events: any[]; // これを使用する際にはドキュメントを参照して詳細な型注釈を記載してください。
  status: FlowTxStatus;
  statusString: string; // これを使用する際にはドキュメントを参照して詳細な型注釈を記載してください。
  errorMessage: string;
  statusCode: FlowTxStatusCode;
};

// Flow の TransactionStatusObject の status に対応している。
// 各ステータスの番号も Flow 側で決まっている。
// https://developers.flow.com/tools/clients/fcl-js/api#transaction-statuses
// 各ステータスの説明は以下。
// https://developers.flow.com/tools/clients/fcl-js/sdk-guidelines#get-transactions
//
// NOTE:
//   もし独自のステータスを追加する場合は、Flow が将来ステータスを追加したとしても問題ないように、
//   番号を十分大きな値にするなどしてください。
export enum FlowTxStatus {
  UNKNOWN = 0,
  PENDING = 1,
  FINALIZED = 2,
  EXECUTED = 3,
  SEALED = 4,
  EXPIRED = 5,

  // 独自のステータス
  INIT = 90,
}

// Flow の TransactionStatusObject の statusCode に対応している。
// > The pass/fail status. 0 indicates the transaction succeeded, 1 indicates it failed.
// https://developers.flow.com/tools/clients/fcl-js/api#transactionstatusobject
//
// NOTE:
//   もし独自のステータスを追加する場合は、Flow が将来ステータスを追加したとしても問題ないように、
//   番号を十分大きな値にするなどしてください。
export enum FlowTxStatusCode {
  SUCCEEDED = 0,
  FAILED = 1,

  // 独自のステータス
  INIT = 90,
  ERROR = 99,
}

// https://developers.flow.com/tools/clients/fcl-js/api#grpc-statuses
export enum GrpcStatus {
  OK = 0,
  CANCELLED = 1,
  UNKNOWN = 2,
  INVALID_ARGUMENT = 3,
  DEADLINE_EXCEEDED = 4,
  NOT_FOUND = 5,
  ALREADY_EXISTS = 6,
  PERMISSION_DENIED = 7,
  RESOURCE_EXHAUSTED = 8,
  FAILED_PRECONDITION = 9,
  ABORTED = 10,
  OUT_OF_RANGE = 11,
  UNIMPLEMENTED = 12,
  INTERNAL = 13,
  UNAVAILABLE = 14,
  DATA_LOSS = 15,
  UNAUTHENTICATED = 16,
}

export enum AssetStatus {
  DONE = "Done",
  PROCESSINIG = "Processing",
  FAILED = "Failed",
  DELETED = "Deleted",
}

export enum TrmFlowTxStatus {
  DONE = "Done",
  PROCESSINIG = "Processing",
  FAILED = "Failed",
  UNKNOWN = "Unknown",
}

export enum MvpApiVersion {
  MVP_V10 = "1.0",
  MVP_V12 = "1.2",
  MVP_V20 = "2.0",
}

export enum TradeOfferStatus {
  AWAITING_ACCEPTANCE = "awaiting_acceptance",
  ACCEPTED = "accepted",
  CANCELED = "canceled",
}

export enum ListingType {
  UNDEFINED = "undefined",
  SALE = "sale",
  RENTAL = "rental",
}

export interface TRMOperationStatus {
  status: APICallStatus;
  errorCode?: string;
  errorMessage?: string;
}

export interface AssetMetadata {
  [key: string]: any;
}

export type Subtitle = {
  language: string;
  b64_data: string;
};
