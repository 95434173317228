import { AjaxStatus, APICallStatus } from "src/service/api_types";
import { BRAND_ACTIONS } from "src/actions/brand_actions";
import { config } from "src/config/config";
import { checkApiStatus } from "src/service/utils";
import { merge } from "lodash";

const defaultState = {
  createdBrands: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  createBrand: {
    status: { status: APICallStatus.INIT },
    response: null,
  },
  updateBrand: {
    status: { status: APICallStatus.INIT },
    response: null,
  },
  createdAssets: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const userBrandsReducer = (state = defaultState, action) => {
  switch (action.type) {
    // create brand
    case BRAND_ACTIONS.CREATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.CREATE_BRAND: ", action.data);
      }

      let newState = {
        createBrand: {
          status: checkApiStatus(action.data.ajaxStat, null),
          response: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.INIT_CREATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.INIT_CREATE_BRAND: ", action.data);
      }

      let newState = {
        createBrand: {
          status: { status: APICallStatus.INIT },
          response: null,
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.START_CREATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.START_CREATE_BRAND: ", action.data);
      }

      let newState = {
        createBrand: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    // get brands
    case BRAND_ACTIONS.GET_CREATED_BRANDS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.GET_CREATED_BRANDS_BY_USER: ", action.data);
      }

      let brands = action.data.response;
      return {
        ...state,
        createdBrands: {
          data: brands,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case BRAND_ACTIONS.INIT_CREATED_BRANDS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.INIT_CREATED_BRANDS_BY_USER: ", action.data);
      }

      let newState = {
        createdBrands: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.START_CREATED_BRANDS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "BRAND_ACTIONS.START_CREATED_BRANDS_BY_USER: ",
          action.data
        );
      }

      let newState = {
        createdBrands: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    // update
    case BRAND_ACTIONS.UPDATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.UPDATE_BRAND: ", action.data);
      }

      let newState = {
        updateBrand: {
          status: checkApiStatus(action.data.ajaxStat, null),
          response: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.INIT_UPDATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.INIT_UPDATE_BRAND: ", action.data);
      }

      let newState = {
        updateBrand: {
          status: { status: APICallStatus.INIT },
          response: null,
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.START_UPDATE_BRAND: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.START_UPDATE_BRAND: ", action.data);
      }

      let newState = {
        updateBrand: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    // get created assets
    case BRAND_ACTIONS.GET_CREATED_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.GET_CREATED_ASSETS_BY_USER: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        createdAssets: {
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case BRAND_ACTIONS.INIT_CREATED_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("BRAND_ACTIONS.INIT_CREATED_ASSETS_BY_USER: ", action.data);
      }

      let newState = {
        createdAssets: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case BRAND_ACTIONS.START_CREATED_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "BRAND_ACTIONS.START_CREATED_ASSETS_BY_USER: ",
          action.data
        );
      }

      let newState = {
        createdAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
