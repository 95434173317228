import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssetGrid from "src/components/asset_grid";
import { setCurrentAsset } from "src/actions/ui/asset_details";
import { AssetCategory } from "src/service/api_types";
import { Content } from "src/service/content_api";

export default function Contents({
  contents,
  assetCategory,
  title,
  isPublic,
  container,
}: {
  contents: Array<Content>;
  assetCategory:
    | AssetCategory.COLLECTED
    | AssetCategory.RENTED
    | AssetCategory.MARKET_PLACE;
  title: string;
  isPublic: boolean;
  container: "collected" | "brandSale" | "brandRental" | "rented";
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onContentClicked = useCallback(
    (content: Content) => {
      const nextPath =
        assetCategory === AssetCategory.COLLECTED
          ? "/asset"
          : assetCategory === AssetCategory.RENTED
          ? "/asset/rented"
          : isPublic && container === "brandSale"
          ? `/asset/${content.kid}`
          : isPublic && container === "brandRental"
          ? `/asset/rental/${content.kid}`
          : "/";
      dispatch(
        setCurrentAsset({
          public: isPublic,
          category: assetCategory,
          asset: content,
        })
      );
      navigate(nextPath);
    },
    [dispatch, navigate, assetCategory, isPublic, container]
  );

  return (
    <>
      {contents.length > 0 ? (
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="text-lg font-bold">{title}</div>
            <AssetGrid
              assets={contents}
              onAssetClicked={(content: Content) => onContentClicked(content)}
              container={container}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
