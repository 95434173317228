import { merge } from "lodash";
import { config } from "src/config/config";
import { ORDER_ACTIONS } from "src/actions/order_actions";
import { APICallStatus } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";

const defaultState = {
  buyAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
    errorMessages: [],
  },
  buyGroupAssets: {
    status: { status: APICallStatus.INIT },
    transaction: null,
    errorMessages: [],
  },
  rentAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
    errorMessages: [],
  },
  tradeAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
    errorMessages: [],
  },
  bidAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
};

export const orderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ORDER_ACTIONS.BUY_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.BUY_ASSET: ", action.data);
      }

      let newState = {
        buyAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
          errorMessages: action.data.errorMessages,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.INIT_BUY_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.INIT_BUY_ASSET: ", action.data);
      }

      let newState = {
        buyAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.BUY_GROUP_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.BUY_GROUP_ASSETS: ", action.data);
      }

      let newState = {
        buyGroupAssets: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
          errorMessages: action.data.errorMessages,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.INIT_BUY_GROUP_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.INIT_BUY_GROUP_ASSETS: ", action.data);
      }

      let newState = {
        buyGroupAssets: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.RENT_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.RENT_ASSET: ", action.data);
      }

      let newState = {
        rentAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
          errorMessages: action.data.errorMessages,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.INIT_RENT_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.INIT_RENT_ASSET: ", action.data);
      }

      let newState = {
        rentAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.TRADE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.TRADE_ASSET: ", action.data);
      }

      let newState = {
        tradeAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
          errorMessages: action.data.errorMessages,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.INIT_TRADE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.INIT_TRADE_ASSET: ", action.data);
      }

      let newState = {
        tradeAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.INIT_BID_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.INIT_BID_ASSET: ", action.data);
      }

      let newState = {
        bidAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case ORDER_ACTIONS.BID_ASSET: {
      if (config.APP_DEBUG) {
        console.log("ORDER_ACTIONS.BID_ASSET: ", action.data);
      }

      let newState = {
        bidAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
