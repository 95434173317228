import { config } from "src/config/config";
import {
  createBrandAPI,
  getBrandsByUserAPI,
  updateBrandAPI,
  getCreatedAssetsByUserAPI,
} from "src/service/brand_asset_api";
import { AjaxStatus } from "src/service/api_types";
import { fclLogout } from "./flow_actions";

export const BRAND_ACTIONS = {
  // create brand
  INIT_CREATE_BRAND: "INIT_CREATE_BRAND",
  START_CREATE_BRAND: "START_CREATE_BRAND",
  CREATE_BRAND: "CREATE_BRAND",
  // get brands
  INIT_CREATED_BRANDS_BY_USER: "INIT_BRANDS_BY_USER",
  START_CREATED_BRANDS_BY_USER: "START_BRANDS_BY_USER",
  GET_CREATED_BRANDS_BY_USER: "GET_BRANDS_BY_USER",
  // update brand
  UPDATE_BRAND: "UPDATE_BRAND",
  INIT_UPDATE_BRAND: "INIT_UPDATE_BRAND",
  START_UPDATE_BRAND: "START_UPDATE_BRAND",
  // get created assets
  INIT_CREATED_ASSETS_BY_USER: "INIT_CREATED_ASSETS_BY_USER",
  START_CREATED_ASSETS_BY_USER: "START_CREATED_ASSETS_BY_USER",
  GET_CREATED_ASSETS_BY_USER: "GET_CREATED_ASSETS_BY_USER",
};

export const initCreateBrand = () => ({
  type: BRAND_ACTIONS.INIT_CREATE_BRAND,
});

export const startCreateBrand = () => ({
  type: BRAND_ACTIONS.START_CREATE_BRAND,
});

export const createBrand = (data: any) => ({
  type: BRAND_ACTIONS.CREATE_BRAND,
  data,
});

export const initCreatedBrandsByUser = () => ({
  type: BRAND_ACTIONS.INIT_CREATED_BRANDS_BY_USER,
});

export const startCreatedBrandsByUser = () => ({
  type: BRAND_ACTIONS.START_CREATED_BRANDS_BY_USER,
});

export const getCreatedBrandsByUser = (data: any) => ({
  type: BRAND_ACTIONS.GET_CREATED_BRANDS_BY_USER,
  data,
});

export const updateBrand = (data: any) => ({
  type: BRAND_ACTIONS.UPDATE_BRAND,
  data,
});

export const initUpdateBrand = () => ({
  type: BRAND_ACTIONS.INIT_UPDATE_BRAND,
});

export const startUpdateBrand = () => ({
  type: BRAND_ACTIONS.START_UPDATE_BRAND,
});

export const initCreatedAssetsByUser = () => ({
  type: BRAND_ACTIONS.INIT_CREATED_ASSETS_BY_USER,
});

export const startCreatedAssetsByUser = () => ({
  type: BRAND_ACTIONS.START_CREATED_ASSETS_BY_USER,
});

export const getCreatedAssetsByUser = (data: any) => ({
  type: BRAND_ACTIONS.GET_CREATED_ASSETS_BY_USER,
  data,
});

export const createBrandDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    name: params.name,
    account: params.account,
    description: params.description,
    kids: params.kids,
    hero_image: params.hero_image,
  };

  dispatch(startCreateBrand());

  return createBrandAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Created brand: ", data);
      }
      dispatch(
        createBrand({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in Creating brand");
      }
      dispatch(
        createBrand({
          response: JSON.parse(err.responseText),
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const getCreatedBrandsByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
    };

    dispatch(startCreatedBrandsByUser());

    return getBrandsByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received brands created by user: ", data);
        }
        let brandsData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getCreatedBrandsByUser(brandsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting brands created by user");
        }
        let brandsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getCreatedBrandsByUser(brandsData));
      });
  };

export const updateBrandDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    brandID: params.brandID,
    name: params.name,
    account: params.account,
    description: params.description,
    kids: params.kids,
    hero_image: params.hero_image,
  };

  dispatch(startUpdateBrand());

  return updateBrandAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Updated asset: ", data);
      }
      dispatch(
        updateBrand({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in updating asset");
      }
      dispatch(
        updateBrand({
          response: JSON.parse(err.responseText),
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const getCreatedAssetsByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      brandId: params.brandId,
    };

    dispatch(startCreatedAssetsByUser());

    return getCreatedAssetsByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets created by user: ", data);
        }
        let assetsData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getCreatedAssetsByUser(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets created by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getCreatedAssetsByUser(assetsData));
      });
  };
