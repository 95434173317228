import {
  GetRequest,
  PostRequestJson,
  PutRequestJson,
} from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const getCurrentAccountInfoAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_account}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    user_currency: options.userCurrency,
  };
  return GetRequest(url, data, headers);
};

export const updateCurrentAccountInfoAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_account}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    username: options.username,
    email: options.email,
    first_name: options.firstName,
    last_name: options.lastName,
  };
  return PutRequestJson(url, data, headers);
};

export const getUserInfoAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.account_info(options.userID)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const initializeAccountAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.init_account}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return PostRequestJson(url, {}, headers, headers);
};

export const confirmAccountInitAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.init_account}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    email: options.email,
  };
  return PutRequestJson(url, data, headers, headers);
};

export const getUserBidsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_bids}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: "bid_timestamp DESC",
  };

  return GetRequest(url, data, headers);
};

export const getUserPointsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.point_details}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const getUserTradeOfferHistoriesAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.trade_offer_histories}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const getUserPointBalanceAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.point_balance}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    asset_token_id: options.assetTokenId,
  };

  return GetRequest(url, data, headers);
};

export const getShowingMenuSettingsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.showing_menu_settings}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};
