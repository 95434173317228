import { merge } from "lodash";
import { config } from "src/config/config";
import { PUBLIC_ASSET_ACTIONS } from "src/actions/public_asset_actions";
import { APICallStatus } from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import moment from "moment";

const defaultState = {
  allAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  saleAssetsInTheBrand: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  rentalAssetsInTheBrand: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  groupsInTheBrand: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  tradingAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  tradableListedAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  newRelease: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  topSales: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  mostPopular: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  byCreator: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
    lastUpdate: null,
  },
  currentAsset: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  assetDetail: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
};

export const publicAssetsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PUBLIC_ASSET_ACTIONS.INIT_ALL_PUBLIC_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_ALL_PUBLIC_ASSETS: ",
          action.data
        );
      }

      let newState = {
        allAssets: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_ALL_PUBLIC_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_ALL_PUBLIC_ASSETS: ",
          action.data
        );
      }

      let newState = {
        allAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_ALL_PUBLIC_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_ALL_PUBLIC_ASSETS: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        allAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.allAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }

    case PUBLIC_ASSET_ACTIONS.GET_SALE_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_SALE_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        saleAssetsInTheBrand: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.saleAssetsInTheBrand.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.INIT_SALE_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_SALE_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let newState = {
        saleAssetsInTheBrand: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_SALE_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_SALE_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let newState = {
        saleAssetsInTheBrand: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_RENTAL_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_RENTAL_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        rentalAssetsInTheBrand: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.rentalAssetsInTheBrand.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }

    case PUBLIC_ASSET_ACTIONS.INIT_RENTAL_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_RENTAL_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let newState = {
        rentalAssetsInTheBrand: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_RENTAL_ASSETS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_RENTAL_ASSETS_BY_BRAND: ",
          action.data
        );
      }

      let newState = {
        rentalAssetsInTheBrand: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_GROUPS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.GET_GROUPS_BY_BRAND: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        groupsInTheBrand: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.groupsInTheBrand.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.INIT_GROUPS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.INIT_GROUPS_BY_BRAND: ", action.data);
      }

      let newState = {
        groupsInTheBrand: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_GROUPS_BY_BRAND: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_GROUPS_BY_BRAND: ",
          action.data
        );
      }

      let newState = {
        groupsInTheBrand: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_TRADING_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.GET_TRADING_ASSETS: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        tradingAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.tradingAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }

    case PUBLIC_ASSET_ACTIONS.INIT_TRADING_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.INIT_TRADING_ASSETS: ", action.data);
      }

      let newState = {
        tradingAssets: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_TRADING_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.START_TRADING_ASSETS: ", action.data);
      }

      let newState = {
        tradingAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_TRADABLE_LISTED_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_TRADABLE_LISTED_ASSETS: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        tradableListedAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.tradableListedAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }

    case PUBLIC_ASSET_ACTIONS.INIT_TRADABLE_LISTED_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_TRADABLE_LISTED_ASSETS: ",
          action.data
        );
      }

      let newState = {
        tradableListedAssets: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_TRADABLE_LISTED_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_TRADABLE_LISTED_ASSETS: ",
          action.data
        );
      }

      let newState = {
        tradableListedAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.INIT_NEW_RELEASE_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_NEW_RELEASE_ASSETS: ",
          action.data
        );
      }

      let newState = {
        newRelease: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_NEW_RELEASE_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_NEW_RELEASE_ASSETS: ",
          action.data
        );
      }

      let newState = {
        newRelease: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_NEW_RELEASE_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_NEW_RELEASE_ASSETS: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        newRelease: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.newRelease.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.GET_TOP_SALES_ASSETS: {
      if (config.APP_DEBUG) {
        console.log("PUBLIC_ASSET_ACTIONS.GET_TOP_SALES_ASSETS: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        topSales: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.topSales.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.INIT_TOP_SALES_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_TOP_SALES_ASSETS: ",
          action.data
        );
      }

      let newState = {
        topSales: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_TOP_SALES_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_TOP_SALES_ASSETS: ",
          action.data
        );
      }

      let newState = {
        topSales: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_MOST_POPULAR_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_MOST_POPULAR_ASSETS: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        mostPopular: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.mostPopular.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.INIT_MOST_POPULAR_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_MOST_POPULAR_ASSETS: ",
          action.data
        );
      }

      let newState = {
        mostPopular: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_MOST_POPULAR_ASSETS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_MOST_POPULAR_ASSETS: ",
          action.data
        );
      }

      let newState = {
        mostPopular: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_ASSETS_BY_CREATOR: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_ASSETS_BY_CREATOR: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        byCreator: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.byCreator.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
          lastUpdate: moment(),
        },
      };
    }
    case PUBLIC_ASSET_ACTIONS.INIT_ASSETS_BY_CREATOR: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_ASSETS_BY_CREATOR: ",
          action.data
        );
      }

      let newState = {
        byCreator: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_ASSETS_BY_CREATOR: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_ASSETS_BY_CREATOR: ",
          action.data
        );
      }

      let newState = {
        byCreator: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_PUBLIC_ASSETS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_PUBLIC_ASSETS_DETAILS: ",
          action.data
        );
      }

      let asset = action.data.response;
      let newState = {
        currentAsset: {
          data: asset,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.GET_SPECIFIC_ASSETS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.GET_SPECIFIC_ASSETS_DETAILS: ",
          action.data
        );
      }

      let asset = action.data.response;
      let newState = {
        assetDetail: {
          data: asset,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.INIT_SPECIFIC_ASSETS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.INIT_SPECIFIC_ASSETS_DETAILS: ",
          action.data
        );
      }

      let newState = {
        assetDetail: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case PUBLIC_ASSET_ACTIONS.START_SPECIFIC_ASSETS_DETAILS: {
      if (config.APP_DEBUG) {
        console.log(
          "PUBLIC_ASSET_ACTIONS.START_SPECIFIC_ASSETS_DETAILS: ",
          action.data
        );
      }

      let newState = {
        assetDetail: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
