import { useRoutes, BrowserRouter } from "react-router-dom";
import { routes } from "src/routes/index";

const Routes = () => {
  let element = useRoutes(routes);
  return <>{element}</>;
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default AppRouter;
