import { GetRequest, PostRequestJson } from "src/utils/rest_api_util";
import { config } from "src/config/config";

export const depositUsdcAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.deposit_usdc}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  let data: Record<string, any> = {
    amount: options.amount,
  };

  return PostRequestJson(url, data, headers);
};

export const depositCashAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.deposit_cash}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  let data: Record<string, any> = {
    currency: "USD",
    amount: options.amount,
  };

  return PostRequestJson(url, data, headers);
};

export const getCashBalanceAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_balance}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const getUserTransactionsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.current_transactions}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: "transaction_timestamp DESC",
  };

  return GetRequest(url, data, headers);
};
